import styled from "styled-components"
import { Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const TopWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  border-radius: 20px;
  background-color: white;
`

export const TextWrapper = styled.div``

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 1em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0 3em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    padding-bottom: 1em;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  padding: 2em 0;
`

export const Image = styled(CustomImage)`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  img {
    border-radius: 20px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: calc(100vh - 310px);
    min-height: 600px;
    max-height: 685px;
  }
`

export const BottomWrapper = styled.div``

export const TopTitle = styled.h2`
  color: #566171;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 24px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const MiddleTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: 48px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 0.5em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0;
  }
`

export const BottomTitle = styled.h4`
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: 36px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${props => props.bgColor};
  text-align: center;
  padding-bottom: 0;
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${props => props.color};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: #a0aaba;
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  letter-spacing: 0;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const TextWrapper = styled.div`
  padding-top: 4rem;
  max-width: 630px;
  margin: auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0 1em;
  max-width: 350px;
  margin: auto;
  column-gap: 2em;
`

export const Image = styled(CustomImage)`
  max-width: 878px;
  margin: auto;
  padding: 3em 0 0;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: 200px;
  }
`

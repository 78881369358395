import React from "react"

import * as S from "./partners-hero.styles"

const PartnersHero = ({
  image,
  title,
  description,
  button,
  pageTitle,
  whiteDescription,
}) => {
  return (
    <S.BgImage img={image ?? image} tag="div" alt="BgImageHero">
      <S.Wrapper>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
        {button && (
          <S.ButtonContainer>
            <S.Button href={button.url} className="blue">
              {button.title}
            </S.Button>
          </S.ButtonContainer>
        )}
      </S.Wrapper>
    </S.BgImage>
  )
}

export default PartnersHero

import React from "react"
import * as S from "./ready-content.styles"
import { Container, Grid } from "@mui/material"
import ContentCard from "../../components/content-card/content-card.component"

const ReadyContent = ({ title, cards }) => {
  if (!cards) return null
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        {title && <S.Title>{title}</S.Title>}
        <Grid container spacing={8}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={`content-card-${index}`}>
              <ContentCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default ReadyContent

import React from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import * as S from "./forge-apps.styles"
import CustomButton from "../../components/custom-button/custom-button.component"

const ForgeApps = ({ backgroundImage, title, description, apps, link }) => {
  return (
    <S.Section>
      <S.Image img={backgroundImage} alt="bgapps" />
      <S.ContentContainer>
        <S.Wrapper>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.IconsContainer container justifyContent="center">
            {apps?.map(({ logo, description }, index) => (
              <S.Item item xs={6} md={2.4} key={index - description}>
                <S.CardLogo>
                  <S.Image img={logo} alt={description} />
                  <S.Description className="logo">{description}</S.Description>
                </S.CardLogo>
              </S.Item>
            ))}
          </S.IconsContainer>
          <S.LinkContainer>
            <CustomButton className="dark-transparent" href={link?.url}>
              {link?.title}
            </CustomButton>
            {/*<S.LinkWrapper url={link.url}>*/}
            {/*  <S.LinkText>{link.title}</S.LinkText>*/}
            {/*  <ArrowForwardIcon />*/}
            {/*</S.LinkWrapper>*/}
          </S.LinkContainer>
        </S.Wrapper>
      </S.ContentContainer>
    </S.Section>
  )
}

export default ForgeApps

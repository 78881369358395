import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  //padding: 0;
  //height: 45%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.breakpoints.up("md")} {
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.primary.main} 50%,
      ${({ theme }) => theme.palette.tertiary.main} 50%
    );

    //height: 100%;
  }
`

export const BlackDiv = styled.div`
  // ${({ theme }) => theme.breakpoints.up("md")} {
  //   //height: 45%;
  //   //background-color: ${({ theme }) => theme.palette.primary.main};
  // }
`

export const GrayDiv = styled.div`
  // ${({ theme }) => theme.breakpoints.up("md")} {
  //   height: 55%;
  //   //background-color: ${({ theme }) => theme.palette.tertiary.main};
  // }
`

export const TopWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 1000px;
  }
`

export const Title = styled.h1`
  color: white;
  padding-top: 3em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 44px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 1.5rem;
  }
`

export const CardWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    width: 100%;
    top: 30%;
  }
`

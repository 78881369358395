import styled, { keyframes } from "styled-components"

const jump = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const GrayShadow = styled.img`
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -48%) scale(0.5);
`

export const GreenShadow = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Icon = styled.img`
  transform: translateY(0);
  animation: ${jump} 3s infinite ease;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
`
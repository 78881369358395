import React, { useEffect, useState } from "react"

import * as S from "./careers.styles"
import { GREENHOUSE_API } from "../../apis/apis"
import DialogGreenhouse from "../../components/dialog-greenhouse/dialog-greenhouse.component"
import { isBrowser } from "../../utils"
import queryString from "query-string"

const Careers = ({ title, description }) => {
  const [departments, setDepartments] = useState(null)
  const [jobId, setJobId] = useState(null)
  console.log("departments", departments)

  const handleJobClick = id => {
    window.location.href = `/about/careers/?gh_jid=${id}`
  }

  useEffect(() => {
    GREENHOUSE_API.get("/departments").then(response => {
      setDepartments(response?.data?.departments)
    })
    if (isBrowser()) {
      const parameters = window.location.search
        ? queryString.parse(window.location.search)
        : ""
      if (parameters !== "") {
        if ("gh_jid" in parameters) {
          setJobId(parameters["gh_jid"])
        }
      }
    }
  }, [])
  return (
    <S.Section id="careers">
      {jobId && <DialogGreenhouse />}
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        {departments?.map(({ jobs }) => {
          if (!jobs || !jobs.length) return null
          return jobs.map(({ location, absolute_url, title, id }, index) => (
            <S.Content
              key={`${index}-${title}`}
              onClick={() => handleJobClick(id)}
            >
              <S.Career>{title}</S.Career>
              <S.Place>{location.name}</S.Place>
            </S.Content>
          ))
        })}
      </S.Wrapper>
    </S.Section>
  )
}

export default Careers

import React from "react"

import * as S from "./software-platform.styles"

const SoftwarePlatform = ({ title, description, image }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <S.Image img={image} alt="software" />
      </S.Wrapper>
    </S.Section>
  )
}

export default SoftwarePlatform

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import TabPanel from "@mui/lab/TabPanel"
import { TabContext } from "@mui/lab"
import CustomImage from "../../components/custom-image/custom-image.component"
import Container from "@mui/material/Container"
import { Grid } from "@mui/material"

export const Section = styled(SectionWrapper)`
  padding: 0;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 3rem;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  &.tab {
    text-align: left;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding: 0;
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 1.5rem;
      line-height: normal;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 1rem;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const CustomTabs = styled(Tabs)`
  .MuiTabs-scrollButtons {
    display: none;
    ${({ theme }) => theme.breakpoints.down("md")} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .MuiTabs-indicator {
    background-color: #d8d8d8;
    height: 5px;
  }
`
export const CustomTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(27)};
  font-weight: 500;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-width: 270px;
  }
`
export const CustomTabPanel = styled(TabPanel)`
  background-color: #d8d8d8;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`
export const CustomTabContext = styled(TabContext)``
export const ContentContainer = styled.div`
  max-width: 415px;
  margin: auto;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  margin-bottom: 1.5rem;
`
export const Image = styled(CustomImage)`
  width: 100%;
  
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 300px !important;
    margin-bottom: 1.5em;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain!important;
    }
  }
  
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 500px !important;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`
export const Wrapper = styled(Container)``
export const GridContainer = styled(Grid)`
  //height: 500px;
  .MuiGrid-item {
    padding: 0;
    ${({ theme }) => theme.breakpoints.down("md")} {
      padding-left: 1.5rem;
      padding-top: 1.5rem;
    }
  }
`

import React from "react"
import * as S from "./green-icon.styles"

const GreenIcon = ({}) => {
  return (
    <S.ImageWrapper>
      <S.GreenShadow
        src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/Bitmap.png"
        alt="Green Shadow"
      />
      <S.GrayShadow
        src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/Group-39.png"
        alt="Gray Shadow"
      />
      <S.IconWrapper>
        <S.Icon
          src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/Bitmap-2.png"
          alt="Task Canvas Icon"
        />
      </S.IconWrapper>
    </S.ImageWrapper>
  )
}

export default GreenIcon

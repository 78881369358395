import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { Grid } from "@mui/material"

export const CarrouselItem = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
`

export const CarrouselImage = styled(CustomImage)`
  display: block;
  position: relative;

  height: 350px;
`

export const MapImg = styled(CustomImage)`
  height: 207px;
  width: 182px;
  display: none;

  @media (min-width: 710px) {
    display: block;
  }
`

export const CarrouselOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  height: 100%;
  width: 100%;
`

export const GridContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);

  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 65px;
    right: 65px;
    top: 10px;
  }

  @media (max-width: 710px) {
    text-align: center;
  }
`

export const Title = styled.h2`
  color: white;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 2.3rem;
  letter-spacing: 0;
  font-weight: 500;
`

export const Description = styled.p`
  color: #a0aaba;
  font-family: ${({ theme }) => theme.fonts.primary};
  width: 100%;
  max-width: 300px;
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 400;

  @media (max-width: 710px) {
    max-width: 100%;
  }
`

import styled from "styled-components"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Container } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  background-color: #000000;
`
export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -125px;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-top: 0;
  }
`
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.tertiary.main};
  padding-bottom: 2.5rem;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  max-width: 672px;
  text-align: center;
  margin: 0;
  padding-bottom: 2.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
export const Button = styled(CustomButton)``
export const ButtonContainer = styled.div``

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: 250px;
  }
`

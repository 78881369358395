import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  text-align: center;
  font-size: 2em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  padding-bottom: 1em;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Button = styled(CustomButton)`
  text-transform: none;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2em;
  padding-bottom: 3em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    row-gap: 1em;
    flex-direction: column;
  }
`
export const CheckContainer = styled.div`
  width: 16px;
  height: 16px;
`
export const Check = styled(CheckCircleOutlineIcon)`
  min-width: 16px;
  min-height: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
`
export const DescriptionContainer = styled(Grid)`
  max-width: 918px;
  margin: auto;
`
export const Description = styled.p`
  padding-bottom: 1em;
  margin: 0;
  font-size: 1em;
  color: ${({ theme }) => theme.palette.text.content};
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 406px;
  }
`
export const Service = styled(Grid)``
export const ContentContainer = styled.div``
export const List = styled.div`
  display: flex;
  column-gap: 0.5em;
`

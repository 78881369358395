import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { Typography } from "@mui/material"
import { breakpoints } from "@mui/system"

export const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }
`

export const Icon = styled(CustomImage)`
  width: 48px;
  height: 48px;
`

export const Title = styled.h3`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1em 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 264px;
  }
  text-align: left;
`

export const Description = styled(Typography)`
  color: #566171;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
`

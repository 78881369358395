import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  background-color: ${props => props.color};
  padding-bottom: 0;
`
export const Wrapper = styled(Container)``
export const Image = styled(CustomImage)`
  width: 100%;
  height: 100% !important;
`
export const GridContent = styled(Grid)`
  display: flex;
  align-items: center;
`
export const ContentDiv = styled.div`
  max-width: 479px;
  margin: auto;
`
export const IconImage = styled(CustomImage)`
  margin-bottom: 1.5rem;
  //filter: drop-shadow(0px 5px 10px rgba(0, 255, 103, 0.5));
  filter: drop-shadow(${props => props.colorShadow});
`

export const Title = styled.h2`
  color: ${props => props.color};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  color: ${props => props.color};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(31)};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
`
export const Button = styled(CustomButton)`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(32)};
`
export const Gradient = styled.div`
  width: 100%;
  height: 100%;
`
export const AccordionDiv = styled.div`
  margin-bottom: 1.5rem;
`
export const CustomAccordion = styled(MuiAccordion)`
  background-color: ${props => props.color};
  &.MuiPaper-root {
    position: initial;
    border-bottom: ${props => props.border};
  }
  &.MuiPaper-elevation {
    box-shadow: none;
  }
`
export const CustomAccSummary = styled(MuiAccordionSummary)`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  flex-direction: row-reverse;
  column-gap: 1.2rem;
  transition: 0.3s;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  color: ${props => props.textColor};
  background-color: ${props => props.color};

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(45deg);
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.text.content};
    }
  }
  &.Mui-expanded {
    transition: 0.3s;
    padding-bottom: 0;
  }
`
export const CustomAccDetails = styled(MuiAccordionDetails)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${props => props.textColor};
  background-color: ${props => props.color};
  margin-left: 2.5rem;
`
export const Icon = styled(AddCircleIcon)`
  color: ${props => props.color};
`

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, MenuItem, Select } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: white;
  padding: 2em 0;
  color: #09090b;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 0.5em;
  }
`

export const Title = styled.h1``

export const TopWrapper = styled.div``

export const TopGrid = styled(Grid)`
  padding: 0 0 4em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 2em;
  }
`

export const CustomMenuItem = styled(MenuItem)``

export const CustomSelect = styled(Select)``

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em 0;
`

import React from "react"
import * as S from "./logos-grid.styles"
import { Grid } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"

const LogosGrid = ({ logos }) => {
  return (
    <S.Wrapper>
      <Grid container spacing={5}  alignItems="center">
        {logos.map(({ logo }, index) => (
          <Grid item xs={6} sm={4} md={1.7} key={`brand-logo-${index}`}>
            <S.Image img={logo} />
          </Grid>
        ))}
      </Grid>
    </S.Wrapper>
  )
}

export default LogosGrid

import React from "react"
import * as S from "./forge-hero.styles"
import { Container, useTheme } from "@mui/material"
import parse from "html-react-parser"
import CustomButton from "../../components/custom-button/custom-button.component"
import useMediaQuery from "@mui/material/useMediaQuery"

const ForgeHero = ({
  title,
  description,
  leftButton,
  rightButton,
  image,
  pageTitle,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const videos = {
    DESKTOP_VIDEO:
      "https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/Apps-Icon_Front.25.mp4",
    MOBILE_VIDEO:
      "https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/950-Front.25-1.mp4",
  }
  return (
    <S.Wrapper bgColor={pageTitle === "Forge OS" ? "#ffffff" : "#09090b"}>
      <Container>
        <S.TextWrapper>
          {title && (
            <S.Title color={pageTitle === "Forge OS" ? "black" : "#ffffff"}>
              {parse(title)}
            </S.Title>
          )}
          {description && <S.Description>{parse(description)}</S.Description>}
        </S.TextWrapper>
        {(leftButton || rightButton) && (
          <S.ButtonWrapper>
            {leftButton && (
              <CustomButton
                href={leftButton?.url}
                className="blue"
                target={leftButton?.target}
              >
                {leftButton?.title}
              </CustomButton>
            )}
            {rightButton && (
              <CustomButton
                href={rightButton?.url}
                className="dark-transparent"
                target={rightButton?.target}
              >
                {rightButton?.title}
              </CustomButton>
            )}
          </S.ButtonWrapper>
        )}
        {/*{image && <S.Image img={image} />}*/}
      </Container>
      <S.Video
        playsInline
        autoPlay
        loop
        muted
        src={isMobile ? videos.MOBILE_VIDEO : videos.DESKTOP_VIDEO}
      />
    </S.Wrapper>
  )
}

export default ForgeHero

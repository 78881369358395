import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: normal;
  }
  padding-bottom: 1.5em;
`

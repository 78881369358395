import React from "react"
import * as S from "./case-study-card.styles"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import parse from "html-react-parser"
import { textEllipsis } from "../../utils"

const CaseStudyCard = ({
  excerpt,
  featuredImage,
  logo,
  category,
  industry,
  url,
}) => {
  // console.log(parse(excerpt)[0].props.children)
  return (
    <S.Wrapper>
      <CustomLink url={url}>
        {featuredImage && (
          <S.ImageContainer>
            <S.FeaturedImage img={featuredImage} />
            <S.ImageOverlay className="overlay">
              <S.LinkedInIcon />
            </S.ImageOverlay>
          </S.ImageContainer>
        )}
        {logo && (
          <S.LogoWrapper>
            <S.Logo img={logo} />
          </S.LogoWrapper>
        )}
        {excerpt && (
          <S.Excerpt>
            {textEllipsis(parse(excerpt)[0].props.children, 110)}
          </S.Excerpt>
        )}
        <S.LinkWrapper>
          <S.LinkText>Read More</S.LinkText>
          <ArrowForwardIcon className="arrow" />
        </S.LinkWrapper>
      </CustomLink>
    </S.Wrapper>
  )
}

export default CaseStudyCard

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@mui/material"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`
export const CustomAccordion = styled(Accordion)`
  float: right;
  width: 100%;
`
export const CustomAccSummary = styled(AccordionSummary)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  &.Mui-expanded {
    display: none;
  }
`
export const Wrapper = styled(Container)`
  &.summaryAccordion {
    display: flex;
    //border-bottom: 1px solid #979797;
    //opacity: 0.5;
    //width: 70%;
    //float: right;
  }
  &.summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding-top: ${({ theme }) => theme.typography.pxToRem(31)};
    padding-bottom: ${({ theme }) => theme.typography.pxToRem(31)};
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(68)};
  line-height: ${({ theme }) => theme.typography.pxToRem(72)};
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-weight: 600;
  width: 50%;

  &.titleDetails {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    line-height: ${({ theme }) => theme.typography.pxToRem(48)};
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
  }
  &.titleSummary {
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    line-height: ${({ theme }) => theme.typography.pxToRem(48)};
    width: 80%;
    text-align: right;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 50%;

  &.titleDetails {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.palette.primary.main};
    max-width: 516px;
    width: 100%;
  }
  &.descriptionSummary {
    width: 20%;
    text-align: right;
    padding-right: ${({ theme }) => theme.typography.pxToRem(69)};
  }
`
export const CustomAccDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  margin: 0;
`
export const BgImage = styled(CustomBgImage)`
  display: flex;
  align-items: center;
  min-height: 516px;
  width: 100%;
`
export const Icon = styled(AddCircleOutlineIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
  transform: rotate(45deg);
  cursor: pointer;
`
export const ContentDiv = styled.div``
export const NumberDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

import styled from "styled-components"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

export const Wrapper = styled.div`
  height: 635px;
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(154.46deg, #15151a 0%, #09090b 100%);
`

export const MediaContainer = styled.div`
  height: 45%;
  width: 100%;
`

export const ContentContainer = styled.div`
  height: 55%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled.div`
  color: #ffffff;
  max-width: 80%;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  letter-spacing: 0;
  font-size: ${({ theme }) => theme.typography.pxToRem(36)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
`

export const RightImage = styled(CustomImage)`
  width: 100%;
  height: 100%;
  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 100%;
  }
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all ease;
  &:hover {
    svg {
      transform: translate(5px);
    }
  }
`

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
`

export const ArrowIcon = styled(ArrowForwardIcon)`
  transition: 0.3s all ease;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-left: 4px;
  height: 20px;
`

import React, { useState } from "react"
import * as S from "./tablet-section.styles"
import { Container, Grid } from "@mui/material"
import parse from "html-react-parser"
import CustomDialog from "../../components/custom-dialog/custom-dialog.component"
import PartnersForm from "../../components/partners-form/partners-form.component"
import ScheduleDemoForm from "../../components/schedule-demo-form/schedule-demo-form.component"

const TabletSection = ({ image, title, description, button, reverse }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <Container maxWidth="xl" style={{ height: "100%" }}>
          <Grid
            container
            style={{ height: "100%" }}
            direction={reverse ? "row-reverse" : "row"}
          >
            <Grid item xs={12} md={6}>
              <S.Image img={image} />
            </Grid>
            <Grid item xs={12} md={6}>
              <S.TextWrapper>
                {title && <S.Title>{parse(title)}</S.Title>}
                {description && (
                  <S.Description>{parse(description)}</S.Description>
                )}
                {button && button.title && (
                  <S.Button className="blue" onClick={handleOpen}>
                    {button.title}
                  </S.Button>
                )}
              </S.TextWrapper>
            </Grid>
          </Grid>
        </Container>
      </S.ContentWrapper>
      <CustomDialog open={open} handleClose={handleClose} title="Schedule Demo">
        <ScheduleDemoForm buttonText="Schedule Demo" close={handleClose} />
      </CustomDialog>
    </S.Wrapper>
  )
}

export default TabletSection

import React, { useEffect, useState } from "react"
import * as S from "./case-studies-grid.styles"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import CaseStudiesGridPagination from "../../components/case-studies-grid-pagination/case-studies-grid-pagination.component"

const CaseStudiesGrid = ({}) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpCaseStudy(sort: { fields: date, order: DESC }) {
        nodes {
          title
          uri
          excerpt
          caseStudyBuilder {
            logo {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
          caseStudyCategories {
            nodes {
              slug
            }
          }
        }
      }
      allWpCaseStudyCategory {
        nodes {
          slug
          name
        }
      }
    }
  `)
  const caseStudies = staticQuery.allWpCaseStudy.nodes
  const caseStudiesCategories = staticQuery.allWpCaseStudyCategory.nodes

  const [category, setCategory] = React.useState("")
  const handleChangeCategory = event => {
    setCategory(event.target.value)
  }

  const [casesToShow, setCasesToShow] = useState([])

  useEffect(() => {
    if (caseStudies) {
      setCasesToShow(caseStudies)
    }
  }, [caseStudies])

  useEffect(() => {
    let filteredPosts = []
    if (category) {
      caseStudies.map(item => {
        item.caseStudyCategories.nodes.map(({ slug }) => {
          if (slug === category) {
            filteredPosts.push(item)
          } else if (category === "see-all") {
            filteredPosts = caseStudies
          }
        })
      })
      setCasesToShow(filteredPosts)
    }
  }, [category])

  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <S.TopGrid container id="case-studies">
          <Grid item xs={12} md={10}>
            <S.Title>Case Studies</S.Title>
          </Grid>
          <Grid item xs={12} md={2}>
            {/*<FormControl fullWidth>*/}
            {/*  <InputLabel id="demo-simple-select-helper-label">*/}
            {/*    Category*/}
            {/*  </InputLabel>*/}
            {/*  <S.CustomSelect*/}
            {/*    labelId="demo-simple-select-helper-label"*/}
            {/*    id="demo-simple-select-helper"*/}
            {/*    value={category}*/}
            {/*    label="Category"*/}
            {/*    onChange={handleChangeCategory}*/}
            {/*  >*/}
            {/*    <MenuItem value="see-all">See All</MenuItem>*/}
            {/*    {caseStudiesCategories.map(({ name, slug }, index) => (*/}
            {/*      <MenuItem key={`item-select-${index}`} value={slug}>*/}
            {/*        {name}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </S.CustomSelect>*/}
            {/*</FormControl>*/}
          </Grid>
        </S.TopGrid>
        <CaseStudiesGridPagination
          caseStudies={category === "see-all" ? caseStudies : casesToShow}
        />
      </Container>
    </S.Wrapper>
  )
}

export default CaseStudiesGrid

import React from "react"
import * as S from "./platform-video-section.styles"
import { Container, useMediaQuery, useTheme } from "@mui/material"
import parse from "html-react-parser"

const PlatformVideoSection = ({ title, description }) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Video
          playsInline
          autoPlay
          loop
          muted
          src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/module-1-1-1.mp4"
        />
        <S.InfoWrapper>
          <Container style={{ height: "100%" }}>
            <S.TextWrapper>
              {title && <S.Title>{parse(title)}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
            </S.TextWrapper>
          </Container>
        </S.InfoWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}

export default PlatformVideoSection

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  padding-top: 4rem;
  background-color: white;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 2rem;
  }
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  text-align: center;
  max-width: 629px;
  margin: auto;
  padding-bottom: 1.2em;
  font-size: 2em;
  font-weight: 500;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  text-align: center;
  max-width: 853px;
  margin: auto;
  padding-bottom: 3.5em;
  color: ${({ theme }) => theme.palette.text.content};
  font-size: 1.25em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
export const Image = styled(CustomImage)`
  width: 100%;
  max-width: 628px;
  margin: auto;
`

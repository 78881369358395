import React from "react"
import * as S from "./featured-article.styles.jsx"
import { Grid } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"
import parse from "html-react-parser"

const FeaturedArticle = ({
  featuredImage,
  uri,
  categories,
  title,
  description,
  pageTitle,
}) => {
  return (
    <CustomLink url={uri} id="featuredArticle">
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={12} md={7} lg={8}>
          {featuredImage?.node && (
            <S.ImageContainer>
              <S.FeaturedImage img={featuredImage?.node} />
              <S.ImageOverlay className="overlay">
                {/*{pageTitle === "Articles" && <S.LinkedInIcon />}*/}
                <S.LinkedInIcon />
              </S.ImageOverlay>
            </S.ImageContainer>
          )}
        </Grid>
        <S.ContentGrid item xs={12} md={5} lg={4}>
          <S.ContentWrapper>
            <S.TagName>FEATURED</S.TagName>
            <S.Category>{categories?.nodes[0]?.name}</S.Category>
            {title && <S.Title>{title}</S.Title>}
            <S.Description>{description && parse(description)}</S.Description>
          </S.ContentWrapper>
        </S.ContentGrid>
      </Grid>
    </CustomLink>
  )
}
export default FeaturedArticle

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${props => props.bgColor};
  text-align: center;
  padding-bottom: 2em;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${props => props.color};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }

  &.productivity {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: #000000;
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    letter-spacing: ${({ theme }) => theme.typography.pxToRem(1)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`

export const Description = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: #a0aaba;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
`

export const TextWrapper = styled.div`
  max-width: 626px;
  margin: auto;
`

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 24px;
  margin-right: 0.3em;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.3s ease;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }

  &:hover {
    svg {
      transform: translateX(25%);
    }
  }
`

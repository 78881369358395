import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Wrapper = styled(CustomBgImage)`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`

export const ContentWrapper = styled.div`
  height: 700px;
  display: flex;
  align-items: center;
`

export const TextWrapper = styled.div`
  max-width: 450px;
  ${({ orientation }) =>
    orientation === "right" &&
    `margin-left:auto;
    `}
  ${({ textColor }) =>
    textColor === "white" &&
    `color:#ffffff;
    `}
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 1.5em;
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const Button = styled(CustomButton)`
  margin-top: 1em;
`

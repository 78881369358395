import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"
import CustomImage from "../custom-image/custom-image.component"
import { LinkedIn } from "@mui/icons-material"

export const CardContainerLink = styled(CustomLink)`
  &:hover {
    h3 {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
    .overlay {
      opacity: 1;
    }
  }
`

export const ImageContainer = styled.div`
  display: block;
  position: relative;
`

export const Image = styled(CustomImage)`
  border-radius: 5px;
  overflow: hidden;
  height: 275px;
  width: 100%;
  display: block;
`

export const ImageOverlay = styled.div`
  background-color: rgba(0, 119, 179, 0.7);
  border-radius: 5px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease all;
`

export const LinkedInIcon = styled(LinkedIn)`
  color: #ffffff;
  opacity: 1 !important;
  width: 48px;
  height: 48px;
`

export const MemberName = styled.h3`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 2rem;
  font-weight: 500;
  margin-top: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const MemberOccupation = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Section = styled(SectionWrapper)`
  padding: 0;
  background-color: #f8f8f8;
  height: 100% !important;
  width: 100%;
`
export const Wrapper = styled(Container)`
  max-width: 1600px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
  }
`
export const GridContainer = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    .MuiGrid-item {
      padding-left: 0;
    }
  }
`
export const GridImage = styled(Grid)`
  //background: radial-gradient(circle, #31acea 0%, #0077b3 100%);
  //min-height: 610px;
  width: 100%;
  //position: relative;
  ${({ theme }) => theme.breakpoints.down("md")} {
    order: 1;
  }
`
export const GridContent = styled(Grid)`
  background-color: #f8f8f8;
  //min-height: 610px;
  display: flex;
  align-items: center;
`
export const Image = styled(CustomImage)`
  width: 100%;
  height: 100% !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`
export const ContentContainer = styled.div`
  max-width: 448px;
  margin: ${props => props.margin};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: auto;
    max-width: unset;
  }
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  margin-top: 2rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 1rem;
  }
`
export const ButtonContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  //justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 1rem 0;
  }
`
export const Button = styled(CustomButton)``

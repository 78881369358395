import React from "react"
import * as S from "./leadership-team.styles.jsx"
import CardTeamItem from "../../components/card-team-item/card-team-item.component"
import { Grid } from "@mui/material"

const LeadershipTeam = ({ sectionTitle, teamMembers }) => {
  return (
    <S.Wrapper maxWidth="xl" id="leadership">
      <S.SectionTitle>{sectionTitle}</S.SectionTitle>
      <Grid container spacing={3} justifyContent="center">
        {teamMembers?.map(({ image, occupation, name, linkedinUrl }, index) => (
          <Grid item xs={12} sm={6} md={3} key={`team-member-${index}`}>
            <CardTeamItem
              image={image}
              occupation={occupation}
              name={name}
              linkedinUrl={linkedinUrl}
            />
          </Grid>
        ))}
      </Grid>
    </S.Wrapper>
  )
}
export default LeadershipTeam

import React from "react"
import * as S from "./empower-section.styles"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"

const EmpowerSection = ({ title, description, button, image }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item sm={12} md={6}>
            <S.TextWrapper>
              {title && <S.Title>{title}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
              {button?.title && (
                <CustomButton
                  className="black-transparent"
                  href={button?.url}
                  target={button?.target}
                >
                  {button?.title}
                </CustomButton>
              )}
            </S.TextWrapper>
          </Grid>
          <Grid item sm={12} md={6}>
            <S.Image img={image} />
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default EmpowerSection

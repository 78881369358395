import React from "react"
import CardPlaceMap from "../../components/card-place-map/card-place-map.component"
import { Grid } from "@mui/material"

import * as S from "./places-map-grid.styles"

const PlacesMapGrid = ({ places }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <Grid container justifyContent="center" spacing={5}>
          {places.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index - item.placeName}>
              <CardPlaceMap {...item} />
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default PlacesMapGrid

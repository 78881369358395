import React, { useState } from "react"
import * as S from "./vertical-tabs.styles"
import { Grid } from "@mui/material"
import Tabs from "@mui/material/Tabs"
import VideoPost from "../video-post/video-post.component"
import PropTypes from "prop-types"

const VerticalTabs = ({ title, subtitle, description, tabs, className }) => {
  const [value, setValue] = useState(0)
  const [overlay, setOverlay] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleEnter = (event, newValue) => {
    setOverlay(true)
  }
  const handleLeave = (event, newValue) => {
    setOverlay(false)
  }

  return (
    <>
      {tabs && (
        <S.CustomGrid container>
          <S.LeftGrid item xs={12} md sm>
            <S.ContentWrapper>
              {title && <S.Title className="large">{title}</S.Title>}
              {subtitle && <S.Title>{subtitle}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
            </S.ContentWrapper>
            <S.TabsWrapper>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
              >
                {tabs.map((tab, index) => (
                  <S.CustomTab
                    label={tab.title}
                    value={index}
                    className={className}
                  />
                ))}
              </Tabs>
            </S.TabsWrapper>
          </S.LeftGrid>
          <Grid item xs={12} md sm>
            {tabs.map(
              (tab, index) =>
                value === index &&
                (tab.videoUrl || tab.youtubeId ? (
                  <div onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
                    <VideoPost
                      url={tab.videoUrl}
                      youtubeId={tab.youtubeId}
                      image={tab.image}
                      overlayColor={overlay}
                    />
                  </div>
                ) : (
                  <S.TabImage className="canvas-image" img={tab.image} />
                ))
            )}
          </Grid>
        </S.CustomGrid>
      )}
    </>
  )
}

VerticalTabs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  tabs: PropTypes.array.isRequired,
}

export default VerticalTabs

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import {Typography} from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  padding: 0;
  background-color: #d9d8da;

  ${({theme}) => theme.breakpoints.up("md")} {
    height: 700px;
  }
`

export const Video = styled.video`
  ${({theme}) => theme.breakpoints.down("md")} {
    position: relative;
    order: 1;
    height: 255px;
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
`

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;

  ${({theme}) => theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
  }
`

export const InfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  ${({theme}) => theme.breakpoints.down("md")} {
    position: relative;
    background-color: #d9d8da;
    order: 0;
    padding: 2em 0;
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  ${({theme}) => theme.breakpoints.up("md")} {
    max-width: 350px;
    text-align: left;
  }
`

export const Title = styled.h2`
  font-family: ${({theme}) => theme.fonts.secondary};
  font-size: ${({theme}) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 1.5em;

  ${({theme}) => theme.breakpoints.down("md")} {
    font-size: ${({theme}) => theme.typography.pxToRem(32)};
    line-height: 48px;
    margin-bottom: 0.5em;
    max-width: 260px;
  }
`

export const Description = styled(Typography)`
  font-size: ${({theme}) => theme.typography.pxToRem(16)};
  line-height: ${({theme}) => theme.typography.pxToRem(20)};
  letter-spacing: 0;

  ${({theme}) => theme.breakpoints.down("md")} {
    line-height: 32px;
    padding-bottom: 2rem;
  }
`

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  background-color: white;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 0;
  }
`
export const Wrapper = styled(Container)``
export const GridSection = styled(Grid)``
export const GridContainer = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 4em;
  }
`
export const GridDescription = styled(Grid)`
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-left: 0 !important;
    order: 0;
  }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1em;
`
export const Title = styled.h2`
  font-size: 2em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: left;
  font-weight: 500;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 412px;
    //text-align: left;
  }
`
export const Image = styled(CustomImage)`
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  border-radius: 10px;
  margin-left: ${props => props.marginLeft};

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-height: 350px;
    max-width: 518px;
  }
`

export const TextContainer = styled.div`
  margin-left: ${props => props.marginLeft};
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 412px;
  }
`

export const LeftGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    order: 1;
  }
`


import React, { useState } from "react"
import * as S from "./service-description.styles"

const ServiceDescription = ({ title, serviceItems }) => {
  console.log(title)
  const [toggleState, setToggleState] = useState(0)
  const numbers = ["num1", "num2", "num3"]

  const [number, setNumber] = useState(numbers[0])

  const toggleTab = index => {
    setToggleState(index)
  }
  console.log(serviceItems)
  return (
    <S.Section>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.ButtonContainer>
          {serviceItems?.map(({ buttonName }, index) => (
            <S.Button
              className={index === toggleState ? "black" : "black-transparent"}
              key={title - index}
              onClick={() => toggleTab(index)}
            >
              {buttonName}
            </S.Button>
          ))}
        </S.ButtonContainer>
        <S.DescriptionContainer container>
          <S.Service item sm={12} md={6}>
            <S.ContentContainer>
              {serviceItems[toggleState]?.itemsLeft?.map(
                ({ serviceDescription }) => (
                  <S.List>
                    <S.CheckContainer>
                      <S.Check />
                    </S.CheckContainer>
                    <S.Description>{serviceDescription}</S.Description>
                  </S.List>
                )
              )}
            </S.ContentContainer>
          </S.Service>
          <S.Service item sm={12} md={6}>
            <S.ContentContainer>
              {serviceItems[toggleState]?.itemsRight?.map(
                ({ serviceDescription }) => (
                  <S.List>
                    <S.CheckContainer>
                      <S.Check />
                    </S.CheckContainer>
                    <S.Description>{serviceDescription}</S.Description>
                  </S.List>
                )
              )}
            </S.ContentContainer>
          </S.Service>
        </S.DescriptionContainer>
      </S.Wrapper>
    </S.Section>
  )
}

export default ServiceDescription

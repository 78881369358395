import React from "react"
import { Grid } from "@mui/material"

import * as S from "./card-location.styles"

const CardLocation = ({
  image,
  title,
  subtitle,
  description,
  mapImage,
  slider,
}) => {
  const next = () => {
    slider.current.slickNext()
  }

  const previous = () => {
    slider.current.slickPrev()
  }
  return (
    <S.CardContainer>
      <S.BgImage img={image} alt="location" tag="div">
        {/*<Grid container justifyContent="center" alignItems="center" spacing={3}>*/}
        {/*  <Grid item xs={12} md={6}>*/}
        <S.Wrapper>
          <S.ContentContainer>
            <S.ImageContainer>
              <S.MapImage img={mapImage} alt="map" />
            </S.ImageContainer>
            <S.Title>{title}</S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
            <S.Description>{description}</S.Description>
            <S.CarrouselNav>
              <S.ArrowBackIcon onClick={previous} />
              <S.ArrowNextIcon onClick={next} />
            </S.CarrouselNav>
          </S.ContentContainer>
        </S.Wrapper>
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6}>*/}

        {/*</Grid>*/}
        {/*</Grid>*/}
      </S.BgImage>
    </S.CardContainer>
  )
}

export default CardLocation

import styled from "styled-components"

export const TitleSection = styled.div`
  padding: 3em 0;
  background-color: white;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1.5em;
  }
`

export const Title = styled.h1`
  text-align: center;
  max-width: 852px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(68)};
    line-height: 72px;
  }
`

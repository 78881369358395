import React, { useRef } from "react"
import * as S from "./testimonials.styles"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container } from "@mui/material"
import TestimonialCard from "../../components/testimonial-card/testimonial-card.component"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
const Testimonials = ({ testimonials, title, description, link }) => {
  const slider = useRef()

  const next = () => {
    slider.current.slickNext()
  }

  const previous = () => {
    slider.current.slickPrev()
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    adaptiveHeight: true,
  }

  return (
    <S.Wrapper>
      <Container>
        {title && (
          <S.TitleSection>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TitleSection>
        )}
        <S.SliderWrapper>
          <Slider ref={slider} {...settings}>
            {testimonials?.map(
              ({ description, author, company, role, image }, index) => (
                <TestimonialCard
                  key={`testimonial-card-${index}`}
                  description={description}
                  author={author}
                  company={company}
                  role={role}
                  image={image}
                />
              )
            )}
          </Slider>
        </S.SliderWrapper>
        <S.ArrowBackIcon onClick={previous}>
          <ArrowBack />
        </S.ArrowBackIcon>
        <S.ArrowNextIcon onClick={next}>
          <ArrowForward />
        </S.ArrowNextIcon>
      </Container>
      {link && (
        <S.ButtonContainer>
          <S.Button className="black-transparent" href={link.url}>
            {link.title}
          </S.Button>
        </S.ButtonContainer>
      )}
    </S.Wrapper>
  )
}

export default Testimonials

import React, { useState } from "react"
import * as S from "./forge-card.styles"
import parse from "html-react-parser"
import { RightImage } from "./forge-card.styles"
import CustomDialog from "../custom-dialog/custom-dialog.component"
import WatchDemoForm from "../watch-demo-form/watch-demo-form.component"
import ScheduleDemoForm from "../schedule-demo-form/schedule-demo-form.component"

const ForgeCard = ({
  title,
  description,
  link,
  haveVideo,
  image,
  watchDemo,
}) => {
  const [openWatchDemo, setOpenWatchDemo] = useState(false)
  const handleOpenWatchDemo = () => setOpenWatchDemo(true)
  const handleCloseWatchDemo = () => setOpenWatchDemo(false)

  const [openScheduleDemo, setOpenScheduleDemo] = useState(false)
  const handleOpenScheduleDemo = () => setOpenScheduleDemo(true)
  const handleCloseScheduleDemo = () => setOpenScheduleDemo(false)

  return (
    <S.Wrapper>
      <S.MediaContainer>
        {!haveVideo ? (
          <RightImage img={image} />
        ) : (
          <S.Video
            playsInline
            autoPlay
            loop
            muted
            src="https://readyrobotics.weareshellshock.com/wp-content/themes/JointsWP-CSS-master/assets/videos/forge-experience/module1.mp4"
          />
        )}
      </S.MediaContainer>
      <S.ContentContainer>
        <S.TextWrapper>
          {title && <S.Title>{title}</S.Title>}
          {description && <S.Description>{parse(description)}</S.Description>}
          <S.LinkWrapper
            onClick={watchDemo ? handleOpenWatchDemo : handleOpenScheduleDemo}
          >
            <S.LinkText>{link?.title}</S.LinkText>
            <S.ArrowIcon />
          </S.LinkWrapper>
        </S.TextWrapper>
      </S.ContentContainer>
      <CustomDialog
        open={openWatchDemo}
        handleClose={handleCloseWatchDemo}
        title="Sign Up"
      >
        <WatchDemoForm
          buttonText="Watch the Forge/OS Demo"
          close={handleCloseWatchDemo}
        />
      </CustomDialog>
      <CustomDialog
        open={openScheduleDemo}
        handleClose={handleCloseScheduleDemo}
        title="Schedule Private Demo"
      >
        <ScheduleDemoForm
          buttonText="Schedule Private Demo"
          close={handleCloseScheduleDemo}
        />
      </CustomDialog>
    </S.Wrapper>
  )
}

export default ForgeCard

import styled, { css } from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography, Grid, IconButton, Container } from "@mui/material"
import Slider from "react-slick"
import CustomImage from "../../components/custom-image/custom-image.component"
import { ArrowBack, ArrowForward } from "@mui/icons-material"

const iconStyle = css`
  border: 1px solid #888888;
  color: #888888;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;

  &:hover {
    border: 1px solid #555555;
    color: #555555;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const Wrapper = styled(SectionWrapper)`
  padding: 0;
  background-color: #f5f5f5;
  height: calc(735px + 2em);
  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: auto;
  }
`

export const ContentWrapper = styled.div`
  max-width: 550px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-left: 2em;
    padding-top: 2em;
  }
`

export const ImageWrapper = styled.div`
  //box-shadow: 0 20px 60px 0 #1c72e8;

  margin: auto;
  position: absolute;
  width: 325px;
  height: 500px;
  top: 78px;
  left: -65px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 185px;
    height: 275px;
    top: 44px;
    left: -33px;
    right: 0;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    right: 0;
    width: 305px;
    height: 466px;
    top: 122px;
    left: -50px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    right: 0;
    width: 305px;
    height: 466px;
    top: 78px;
    left: -50px;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "md")} {
    right: 0;
    width: 305px;
    height: 466px;
    top: 78px;
    left: -50px;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0;
  max-width: 400px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const CustomSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
  }

  .slick-slide {
    opacity: 0.3;
    display: flex;
    align-items: center;
    height: auto;
    //height: 180px;
    filter: grayscale(75%);
    transition: 0.5s ease all;
    transform: scale(0.6);
    &.slick-active {
      &.slick-center {
        &.slick-current {
          filter: grayscale(0%);
          transform: scale(0.8);
          object-fit: cover !important;
          opacity: 1;
        }
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }
`
export const CustomContainer = styled(Container)`
  border-radius: 20px;
  //background-color: #ffffff;
  padding-bottom: 2rem;
`

export const SliderWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 1.5em 0 0;
  //height: 216px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: calc(100% - 100px);
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 0;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: 30px;
  }
`

export const Logo = styled(CustomImage)`
  width: 100%;
  height: 100%;
`

export const LogoWrapper = styled.div`
  padding: 0.5em;
  object-fit: contain !important;
`

// export const ArrowBackIcon = styled(ArrowBack)`
//   ${iconStyle};
//   position: absolute;
//   top: 43%;
//   left: -50px;
// `
// export const ArrowNextIcon = styled(ArrowForward)`
//   ${iconStyle};
//   position: absolute;
//   top: 43%;
//   right: -50px;
// `

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ImageSlider = styled(Slider)`
  height: 100%;
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    object-fit: cover;
    > div {
      height: 100%;
    }
  }
`

export const LeftImage = styled(CustomImage)`
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
  }
`

export const LeftWrapper = styled.div`
  position: relative;
  //height: 100%;
  display: block;
  margin: auto;
  padding-bottom: 2em;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 500px;
    padding-bottom: 0;
  }
`

export const TabletWrapper = styled(CustomImage)`
  width: 500px;
  img {
    width: 100%;
    height: 100%;
    //object-fit: contain !important;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 350px;
    width: 300px;
    margin: auto;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: absolute;
    height: 600px;
    top: 45px;
    left: 0;
  }
`
export const CustomGrid = styled(Grid)`
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column-reverse;
  }
`
export const ArrowBackIcon = styled(IconButton)`
  ${iconStyle};
  position: absolute;
  top: 43%;
  left: -30px;
`
export const ArrowNextIcon = styled(IconButton)`
  ${iconStyle};
  position: absolute;
  top: 43%;
  right: -30px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2em;
  }
`
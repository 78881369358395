import React from "react"
import * as S from "./forge-grid.styles"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import FeatureCard from "../../components/feature-card/feature-card.component"

const ForgeGrid = ({ title, feature }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        {title && <S.Title>{title}</S.Title>}
        <Grid container spacing={8} justifyContent="center">
          {feature.map(({ title, icon, description }, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              key={`feature-card-${index}`}
            >
              <FeatureCard
                title={title}
                icon={icon}
                description={description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default ForgeGrid

import React from "react"
import * as S from "./top-brands-info-banner.styles"
import parse from "html-react-parser"
import {Container} from "@mui/material"
import LogosGrid from "../../components/logos-grid/logos-grid.component"
import CustomLink from "../../components/custom-link/custom-link.component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const TopBrandsInfoBanner = ({
                                 title,
                                 description,
                                 brands,
                                 link,
                                 pageTitle,
                             }) => {
    return (
        <S.Wrapper
            bgColor={
                pageTitle === "Forge OS"
                    ? "#ffffff"
                    : pageTitle === "Productivity Apps"
                        ? "#EEF7FF"
                        : "#09090b"
            }
        >
            <Container>
                <S.TextWrapper>
                    {title &&
                        (pageTitle === "Productivity Apps" ? (
                            <S.Title className="productivity">{parse(title)}</S.Title>
                        ) : (
                            <S.Title color={pageTitle === "Forge OS" ? "black" : "#ffffff"}>
                                {parse(title)}
                            </S.Title>
                        ))}
                    {description && <S.Description>{parse(description)}</S.Description>}
                </S.TextWrapper>
                <LogosGrid logos={brands}/>
                {link?.title && link?.url &&
                    <CustomLink url={link?.url}>
                        <S.LinkWrapper>
                            <S.LinkText>{link?.title}</S.LinkText>
                            <ArrowForwardIcon/>
                        </S.LinkWrapper>
                    </CustomLink>}
            </Container>
        </S.Wrapper>
    )
}

export default TopBrandsInfoBanner

import React from "react"
import parse from "html-react-parser"
import { Grid } from "@mui/material"

import * as S from "./developer-description.styles"

const DeveloperDescription = ({ image, title, description, link }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <S.Image img={image} alt="devImage" tag="div">
              <S.Gradient />
            </S.Image>
          </Grid>
          <S.GridContent item xs={12} md={6}>
            <S.ContentDiv>
              <S.Title>{title}</S.Title>
              <S.Description>{parse(description)}</S.Description>
              <S.Button className="dark-transparent" url={link.url}>
                {link.title}
              </S.Button>
            </S.ContentDiv>
          </S.GridContent>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default DeveloperDescription

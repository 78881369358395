import styled from "styled-components"
import Container from "@mui/material/Container"

export const Section = styled.section`
  background-color: white;
`

export const Wrapper = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 0;
    padding-top: 0;
  }
`

export const ContentContainer = styled.div`
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 500;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 36px;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 1.5rem;
      line-height: normal;
    }
  }

  p {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 70%;
  }
`

import React from "react"
import { Grid } from "@mui/material"

import * as S from "./device-control.styles"

const DeviceControl = ({
  iconImage,
  title,
  description,
  image,
  deviceDescriptionItems,
  smallText,
}) => {
  return (
    <S.Section id="Device Control">
      <S.Wrapper>
        <S.ContentContainer>
          {iconImage && <S.IconImage img={iconImage?.sourceUrl} alt="icon" />}
          <S.TopTextWrapper smallText={smallText}>
            <S.Title smallText={smallText}>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TopTextWrapper>
        </S.ContentContainer>
        <S.ImageContainer>
          <S.Image img={image} alt="device" />
        </S.ImageContainer>
        {deviceDescriptionItems && (
          <Grid container spacing={2} justifyContent="center">
            {deviceDescriptionItems.map(
              ({ titleDescription, deviceDescription }, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index - description}
                  style={{ marginTop: "2rem" }}
                >
                  <S.Title className="gridSection">{titleDescription}</S.Title>
                  <S.Description className="deviceItems">
                    {deviceDescription}
                  </S.Description>
                </Grid>
              )
            )}
          </Grid>
        )}
      </S.Wrapper>
    </S.Section>
  )
}

export default DeviceControl

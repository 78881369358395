import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Typography } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"

export const Section = styled(SectionWrapper)`
  background-color: white;
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  text-align: center;
  margin: auto;
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  color: ${({ theme }) => theme.palette.primary.main};
`
export const Description = styled.p`
  text-align: center;
  margin: auto;
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
`
export const Content = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b7b7b7;
  max-width: 853px;
  margin: auto;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`
export const Career = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
  }
`
export const Place = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
`

import React from "react"
import * as S from "./feature-card.styles"

const FeatureCard = ({ title, icon, description }) => {
  return (
    <S.Wrapper>
      <S.Icon img={icon} />
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}
    </S.Wrapper>
  )
}

export default FeatureCard

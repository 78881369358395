import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #ffffff;
`

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: #09090b;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
  p {
    margin: unset;
  }
`
export const Content = styled(Typography)`
  color: #566171;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;

  p {
    margin: unset;
  }
`

export const TextWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 400px;
  }
`

export const LeftGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

export const LeftImage = styled(CustomImage)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 100px;
  }
  //max-width: 400px;
  //margin-right: 0 auto;
`

export const RightGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`

import React from "react"
import { Grid } from "@mui/material"

import * as S from "./forge-os-hero.styles"

const ForgeOsHero = ({ logoImage, title, description, image }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <S.GridText item xs={12} md={6}>
            <S.ContentContainer>
              <S.ImageContainer>
                <S.Image img={logoImage} alt="logo" />
              </S.ImageContainer>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
            </S.ContentContainer>
          </S.GridText>
          <S.GridImage item xs={12} md={6}>
            <S.Image img={image} alt="image" />
          </S.GridImage>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default ForgeOsHero

import React from "react"
import * as S from "./info-row.styles"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import parse from "html-react-parser"

const InfoRow = ({ title, description, content, image }) => {
  return (
    <S.Wrapper>
      <Container>
        <Grid container alignItems="center">
          <S.LeftGrid item xs={12} md={6}>
            <S.TextWrapper>
              {title && <S.Title>{title}</S.Title>}
              {description && (
                <S.Description>{parse(description)}</S.Description>
              )}
              {content && <S.Content>{parse(content)}</S.Content>}
            </S.TextWrapper>
          </S.LeftGrid>
          <S.RightGrid item xs={12} md={6}>
            <S.LeftImage img={image} />
          </S.RightGrid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default InfoRow

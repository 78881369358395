import React, { useEffect, useState } from "react"
import * as S from "./articles-page.styles.jsx"
import FeaturedArticle from "../../components/featured-article/featured-article.component"
import OtherPostGridPagination from "../../components/other-post-grid-pagination/other-post-grid-pagination.component"
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"

const ArticlesPage = ({ featuredArticle, pageTitle }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      post: file(relativePath: { eq: "post-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          categories {
            nodes {
              name
              slug
            }
          }
          uri
          date
          slug
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
        }
      }
      allWpCategory(filter: { slug: { nin: "uncategorized" } }) {
        nodes {
          name
          slug
          posts {
            nodes {
              title
            }
          }
        }
      }
      allWpWhitepaper(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          whitepaperCategories {
            nodes {
              name
              slug
            }
          }
          uri
          date
          slug
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
        }
      }
      allWpWhitepaperCategory(filter: { slug: { nin: "uncategorized" } }) {
        nodes {
          name
          slug
          whitepapers {
            nodes {
              title
            }
          }
        }
      }
    }
  `)
  const articles =
    pageTitle === "Articles"
      ? staticQuery.allWpPost.nodes
      : staticQuery.allWpWhitepaper.nodes

  const postsCategories = (
    pageTitle === "Articles"
      ? staticQuery.allWpCategory
      : staticQuery.allWpWhitepaperCategory
  ).nodes.filter(({ posts }) => posts.nodes.length)

  const [category, setCategory] = useState("")
  const handleChangeCategories = event => {
    setCategory(event.target.value)
  }
  const [articlesToShow, setArticlesToShow] = useState([])

  useEffect(() => {
    if (articles) {
      setArticlesToShow(articles)
    }
  }, [articles])

  useEffect(() => {
    let filteredPosts = []
    if (category) {
      articles.map(item => {
        item.categories.nodes.map(({ slug }) => {
          if (slug === category) {
            filteredPosts.push(item)
          } else if (category === "all") {
            filteredPosts = articles
          }
        })
      })
      setArticlesToShow(filteredPosts)
    }
  }, [category])

  // console.log(featuredArticle)
  return (
    <S.Section>
      <Container maxWidth="xl">
        <S.TopGrid container>
          <Grid item xs={12} md={10}>
            <S.Title>{pageTitle}</S.Title>
          </Grid>
          {/*<Grid item xs={12} md={2}>*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <InputLabel id="demo-simple-select-helper-label">*/}
          {/*      Category*/}
          {/*    </InputLabel>*/}
          {/*    <S.CustomSelect*/}
          {/*      labelId="demo-simple-select-helper-label"*/}
          {/*      id="demo-simple-select-helper"*/}
          {/*      value={category}*/}
          {/*      label="Category"*/}
          {/*      onChange={handleChangeCategories}*/}
          {/*    >*/}
          {/*      <MenuItem value="see-all">See All</MenuItem>*/}
          {/*      {postsCategories.map(({ name, slug }, index) => (*/}
          {/*        <MenuItem key={`item-select-${index}`} value={slug}>*/}
          {/*          {name}*/}
          {/*        </MenuItem>*/}
          {/*      ))}*/}
          {/*    </S.CustomSelect>*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}
        </S.TopGrid>
        <FeaturedArticle
          featuredImage={featuredArticle?.featuredImage}
          title={featuredArticle?.title}
          uri={
            pageTitle === "Articles"
              ? `/articles/${featuredArticle?.slug}`
              : `/resources${featuredArticle?.uri}`
          }
          categories={featuredArticle?.categories}
          description={featuredArticle?.excerpt}
          pageTitle={pageTitle}
        />
        <OtherPostGridPagination
          articles={category === "see-all" ? articles : articlesToShow}
          postPerPage={6}
          pageTitle={pageTitle}
          idFeatured={featuredArticle?.id}
        />
      </Container>
    </S.Section>
  )
}
export default ArticlesPage

import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import CustomButton from "../custom-button/custom-button.component"

export const Wrapper = styled.div``

export const MediaContainer = styled.div``

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  text-align: center;
  margin: 2.5rem auto;
  max-width: 446px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const RightImage = styled(CustomImage)`
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  height: 300px;
`

export const Video = styled.video`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
`

export const Button = styled(CustomButton)``

import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #09090b;
  padding: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 700px;
  }
`

export const TextWrapper = styled.div`
  max-width: 450px;
  margin: auto;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 1rem auto;
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 1.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0.5em;
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
`

export const Button = styled(CustomButton)`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  max-width: 70%;
`

export const Image = styled(CustomImage)`
  max-width: 550px;
  margin: auto;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: bottom;
  }
`

import React from "react"
import * as S from "./deployment-section.styles"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Container, useTheme } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import useMediaQuery from "@mui/material/useMediaQuery"

const DeploymentSection = ({
  backgroundImage,
  title,
  subtitle,
  description,
  button,
  blackButton,
}) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      {!isMD ? (
        <S.Wrapper>
          <S.VideoWrapper>
            <S.Video
              playsInline
              autoPlay
              loop
              muted
              src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Home-Workcell-Wide-3.mp4"
            />
            <S.InfoWrapper>
              <Container style={{ height: "100%" }}>
                <S.TopWrapper>
                  <S.ContentWrapper>
                    <S.Title>{title}</S.Title>
                    <S.Subtitle>{subtitle}</S.Subtitle>
                    <S.Description>{description}</S.Description>
                    <S.Button
                      className="black-transparent"
                      target={button?.target}
                      href={button?.url}
                    >
                      {button?.title}
                    </S.Button>
                  </S.ContentWrapper>
                </S.TopWrapper>
              </Container>
            </S.InfoWrapper>
          </S.VideoWrapper>
        </S.Wrapper>
      ) : (
        <S.MobileWrapper>
          <S.MobileTextWrapper>
            <Container>
              <S.Title>{title}</S.Title>
              <S.Subtitle>{subtitle}</S.Subtitle>
              <S.Description>{description}</S.Description>
              <CustomButton
                className="light-transparent"
                target={button?.target}
                href={button?.url}
              >
                {button?.title}
              </CustomButton>
            </Container>
          </S.MobileTextWrapper>
          <S.MobileVideo
            playsInline
            autoPlay
            loop
            muted
            src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Home-Workcell-Square.mp4"
          />
        </S.MobileWrapper>
      )}
    </>
  )
}

export default DeploymentSection

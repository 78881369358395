import React from "react"
import * as S from "./contact-banner.styles"
import { Container, Grid } from "@mui/material"

const ContactBanner = ({}) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <S.InfoLink href="tel:833-732-3977">
              <S.InfoWrapper>
                <S.Title>Support</S.Title>
                <S.Description>833-READY-SP</S.Description>
              </S.InfoWrapper>
            </S.InfoLink>
          </Grid>
          <Grid item md={4} xs={12}>
            <S.InfoLink href="tel:833-732-3987">
              <S.InfoWrapper>
                <S.Title>Sales</S.Title>
                <S.Description>833-READY-US</S.Description>
              </S.InfoWrapper>
            </S.InfoLink>
          </Grid>
          <Grid item md={4} xs={12}>
            <S.InfoLink href="tel:833-732-3967">
              <S.InfoWrapper>
                <S.Title>Main</S.Title>
                <S.Description>833-732-3967</S.Description>
              </S.InfoWrapper>
            </S.InfoLink>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default ContactBanner

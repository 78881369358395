import styled from "styled-components"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"

export const Wrapper = styled.div`
  color: #566171;
  &:hover {
    svg {
      &.arrow {
        transform: translateX(25%);
      }
    }
  }
`

export const Excerpt = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
  min-height: 55px;
  margin-bottom: 1.5rem;
`

export const FeaturedImage = styled(CustomImage)`
  height: 310px;
  img {
    border-radius: 5px;
  }
`
export const ImageContainer = styled.div`
  display: block;
  position: relative;
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`

export const ImageOverlay = styled.div`
  background-color: rgba(0, 119, 179, 0.7);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
`

export const LinkedInIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #ffffff;
  opacity: 1 !important;
  width: 64px;
  height: 64px;
`
export const Logo = styled(CustomImage)`
  margin-top: 1.25rem;
  margin-bottom: 2rem;
  height: 60px !important;
  width: 200px !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }

  //.gatsby-image-wrapper {
  //  max-height: 40px;
  //  min-height: 40px;
  //  //max-width: 150px;
  //  img {
  //    object-fit: contain !important;
  //  }
  //}
`

export const LogoWrapper = styled.div``

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 24px;
  margin-right: 0.3em;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    transition: all 0.3s ease;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`

import React from "react"
import * as S from "./card-team-item.styles"

const CardTeamItem = ({ image, name, occupation, linkedinUrl }) => {
  return (
    <S.CardContainerLink url={linkedinUrl} target={"_blank"}>
      <S.ImageContainer>
        <S.Image img={image} />
        <S.ImageOverlay className="overlay">
          <S.LinkedInIcon />
        </S.ImageOverlay>
      </S.ImageContainer>
      <S.MemberName>{name}</S.MemberName>
      <S.MemberOccupation>{occupation}</S.MemberOccupation>
    </S.CardContainerLink>
  )
}

export default CardTeamItem

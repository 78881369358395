import React from "react"
import * as S from "./productivity-grid.styles"
import { Container, Grid, useTheme } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import useMediaQuery from "@mui/material/useMediaQuery"
import GreenIcon from "../../components/green-icon/green-icon.component"

const ProductivityGrid = ({
                            leftImage,
                            title,
                            description,
                            button,
                            features,
                          }) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("md"))

  return (
      <S.Wrapper>
        <Container maxWidth="xl">
          <Grid container spacing={8}>
            <Grid item xs={12} md={5}>
              <S.LeftWrapper>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
                <S.ImageWrapper>
                  {isMD ? <S.LeftImage img={leftImage} /> : <GreenIcon />}
                </S.ImageWrapper>
              </S.LeftWrapper>
            </Grid>
            <S.GridLogos item xs={12} md={7}>
              <S.RightWrapper>
                <Grid container spacing={4}>
                  {features.map(
                      ({ title, description, image, shadowColor }, index) => (
                          <Grid item xs={6} key={index - title}>
                            <S.FeatureWrapper>
                              <S.FeatureImage shadowColor={shadowColor} img={image} />
                              <S.FeatureTitle>{title}</S.FeatureTitle>
                              <S.FeatureDescription>
                                {description}
                              </S.FeatureDescription>
                            </S.FeatureWrapper>
                          </Grid>
                      )
                  )}
                </Grid>
                <S.BottomWrapper>
                  <CustomButton className="dark-transparent" href={button?.url}>
                    {button?.title}
                  </CustomButton>
                  {/*<S.Link url={button?.url}>*/}
                  {/*  <S.LinkWrapper>*/}
                  {/*    {button?.title}*/}
                  {/*    <S.Arrow />*/}
                  {/*  </S.LinkWrapper>*/}
                  {/*</S.Link>*/}
                </S.BottomWrapper>
              </S.RightWrapper>
            </S.GridLogos>
          </Grid>
        </Container>
      </S.Wrapper>
  )
}

export default ProductivityGrid
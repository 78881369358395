import styled, { css } from "styled-components"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import CustomImage from "../custom-image/custom-image.component"
import * as Arrow from "../../assets/arrow.svg"
import { Container } from "@mui/material"

const iconStyle = css`
  // color: ${({ theme }) => theme.palette.tertiary.main};
  cursor: pointer;
  width: 40px;
  height: 40px;

  &:hover {
    g {
      transition: 0.5s;
      stroke: white;
    }
  }
`
export const CardContainer = styled.div`
  margin: 3rem 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 1rem 0;
  }
`
export const BgImage = styled(CustomBgImage)`
  //overflow: hidden;
  //border-radius: 20px;
  height: 713px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 650px;
  }
`
export const Wrapper = styled(Container)``
export const ContentContainer = styled.div`
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 20px;
  margin-top: 5rem;
  //margin: 5rem;
  padding: 1rem;
  width: 100%;
  max-width: 448px;
  // ${({ theme }) => theme.breakpoints.up("md")} {
  //   padding: 3.5rem;
  //   max-width: 448px;
  // }
`
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-bottom: 1.2rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Subtitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.tertiary.main};
`
export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 1.2rem;
`
export const ImageContainer = styled.div`
  margin-bottom: 1.5rem;
  .gatsby-image-wrapper {
    max-height: 80px;
    min-height: 70px;
    max-width: 90px;
    //margin: auto;
    //margin-left: auto;
    img {
      object-fit: contain !important;
    }
  }
`
export const MapImage = styled(CustomImage)``

export const CarrouselNav = styled.div`
  display: flex;
`

export const ArrowBackIcon = styled(Arrow)`
  margin-right: 1em;
  ${iconStyle}
`
export const ArrowNextIcon = styled(Arrow)`
  margin-left: 1em;
  transform: rotate(180deg);
  ${iconStyle}
`

import styled from "styled-components"
import Container from "@mui/material/Container"

export const Wrapper = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  //display: flex;
  //flex-direction: column;
  //align-items: center;

  h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: ${({ theme }) => theme.typography.pxToRem(36)};
    font-weight: 500;
    padding: 1rem 0;
  }
  h4 {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
    color: ${({ theme }) => theme.palette.text.content};
    font-weight: 400;
    padding: 1rem 0 0;
  }

  h5 {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: 38px;
    color: ${({ theme }) => theme.palette.text.content};
    font-weight: 400;
    padding: 0.5em 0;
    margin: 0;
  }
  ul {
    li {
      width: 100%;
      margin: 0;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
      color: ${({ theme }) => theme.palette.text.content};
      list-style: none;
      background-image: url(https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/checkmark-bullet.png);
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: 5px;
      background-size: 22px;
      padding: 0 2rem;
    }
  }

  p {
    width: 100%;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    color: ${({ theme }) => theme.palette.text.content};
    padding: 0.5rem 0;
  }
  a {
    color: ${({ theme }) => theme.palette.secondary.main};
    text-decoration: none;
  }
`

import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"

import * as S from "./contact-form.styles"
import Typography from "@mui/material/Typography"
import { Grid } from "@mui/material"
import { useLocation } from "@reach/router"
import Spinner from "../spinner/spinner.component"
import ThankYouMessage from "../thank-you-message/thank-you-message.component"
import { submitGravityForm } from "../../utils"
import queryString from "query-string"
import { navigate } from "gatsby"
import { gtagConversionContactClick } from "../../gtag-utils"

const ContactForm = ({ title, link, textButton }) => {
  const recaptchaRef = useRef()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const handleCaptchaChange = () => {
    setError(false)
  }

  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    setIsLoading(true)
    const parameters = queryString.parse(location.search)
    let utm_source = ""
    if (parameters !== "" && "utm_source" in parameters) {
      utm_source = parameters["utm_source"]
    }

    const captchaVerification = recaptchaRef.current.getValue()
    if (captchaVerification === "") {
      setError(true)
      setIsLoading(false)
      return
    }
    submitGravityForm(
      {
        input_1: data.first_name,
        input_2: data.last_name,
        input_3: data.email,
        input_4: data.company,
        input_5: location.href,
        input_6: utm_source,
      },
      2
    ).then(response => {
      gtagConversionContactClick(location.href)
      setIsLoading(false)
      navigate("/thank-you/")
      // setFormSubmitted(true)
    })
  }

  return (
    <S.FormDiv>
      {isLoading && <Spinner />}
      {!formSubmitted ? (
        <>
          <S.TitleForm>{title}</S.TitleForm>
          <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <S.CustomField
                  required
                  label="First Name"
                  variant="standard"
                  {...register("first_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <S.CustomField
                  required
                  id="lastname"
                  label="Last Name"
                  variant="standard"
                  {...register("last_name")}
                />
              </Grid>
              <Grid item xs={12}>
                <S.CustomField
                  required
                  id="email"
                  label="Email Address"
                  variant="standard"
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <S.CustomField
                  required
                  id="company"
                  label="Company"
                  variant="standard"
                  {...register("company")}
                />
              </Grid>
            </Grid>
            <S.TermsDiv>
              <p>CAPTCHA</p>
              <ReCAPTCHA
                sitekey={process.env.GATSBY_CAPTCHA_CLIENT_KEY}
                size="normal"
                render="explicit"
                onChange={handleCaptchaChange}
                className="g-recaptcha"
                ref={recaptchaRef}
              />
              {error && (
                <Typography style={{ color: "#F54343" }}>
                  Please enter captcha
                </Typography>
              )}
            </S.TermsDiv>
            <S.Link url={link.url}>{link.title}</S.Link>
            <S.ButtonDiv>
              <S.Button className="blue" children={textButton} type="submit" />
            </S.ButtonDiv>
          </S.CustomForm>
        </>
      ) : (
        <ThankYouMessage
          message={`<h3>Thanks for contacting us! </h3> <p>We will get in touch with you shortly.</p>`}
        />
      )}
    </S.FormDiv>
  )
}

export default ContactForm

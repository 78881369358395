import React from "react"
import * as S from "./platform-section.styles"
import { Container } from "@mui/material"
import parse from "html-react-parser"

const PlatformSection = ({
  backgroundImage,
  title,
  description,
  orientation,
  textColor,
  button,
}) => {
  return (
    <S.Wrapper img={backgroundImage}>
      <S.ContentWrapper>
        <Container>
          <S.TextWrapper orientation={orientation} textColor={textColor}>
            {title && <S.Title>{parse(title)}</S.Title>}
            {description && <S.Description>{parse(description)}</S.Description>}
            {button && button.title && (
              <S.Button className="blue">{button.title}</S.Button>
            )}
          </S.TextWrapper>
        </Container>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}

export default PlatformSection

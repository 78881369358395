import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  padding: 0;
  //max-width: 1600px;
  width: 100%;
  margin: auto;
`
export const BgImage = styled(CustomBgImage)`
  padding: 2rem 0;
`
export const Wrapper = styled(Container)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
  }
`
export const ContentContainer = styled.div``
export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
`

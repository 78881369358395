import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: #ffffff;
  padding: 0 0 2em;
`

export const BannerWrapper = styled.div`
  border-radius: 20px;
  padding: 2em;
  background: radial-gradient(circle, #42b1e8 0%, #0077b3 100%);
`

export const TextWrapper = styled.div`
  max-width: 560px;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Title = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: 48px;
  }
`

export const Description = styled(Typography)`
  color: #ffffff;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2em;
  }
`

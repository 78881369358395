import styled from "styled-components"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import { Grid, Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import QuotesIcon from "../../assets/quotes.svg"
import Tab from "@mui/material/Tab"
import ArrowNext from "../../images/arrow.svg"
import BlackArrow from "../../images/arrow-black.svg"

export const CustomGrid = styled(Grid)`
  &,
  .canvas-image {
    height: 100%;
  }
  .canvas-image {
    object-fit: contain !important;
    object-position: top;
  }

  .MuiGrid-item {
    padding-top: 0;
    :first-child {
      padding-left: 0;
    }
  }
`
export const Title = styled.h4`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(22)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 31px;
  &.large {
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 48px;
    padding-bottom: 0.25em;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0 2em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    padding-bottom: 1em;
  }
`

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 2em;
  }
`

export const TabsWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1em;
  }
`
export const TabImage = styled(CustomImage)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 400px;
    width: 300px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain !important;
    }
  }
`
export const CustomTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: flex-start;
  text-transform: capitalize;
  border-radius: 50px;
  &.device-control,
  &.Mui-selected {
    ::after {
      content: "";
      position: absolute;
      right: 1em;
      height: 40px;
      width: 40px;
      background-size: contain;
    }
  }
  &.device-control {
    ::after {
      background: url(${BlackArrow}) no-repeat center center;
    }
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: #ffffff;
    ::after {
      background: url(${ArrowNext}) no-repeat center center;
      transform: rotate(180deg);
    }
  }
  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const LeftGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

import React, { useState } from "react"
import * as S from "./ready-banner.styles"
import { Container } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomDialog from "../../components/custom-dialog/custom-dialog.component"
import SdkBetaForm from "../../components/sdk-beta-form/sdk-beta-form.component"
import { gtagConversionNewsletterClick } from "../../gtag-utils"
import { useLocation } from "@reach/router"

const ReadyBanner = ({ title, description, button }) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const handleOpen = () => {
    setOpen(true)
    gtagConversionNewsletterClick(location.href)
  }
  const handleClose = () => setOpen(false)

  return (
    <S.Wrapper>
      <Container>
        <S.BannerWrapper>
          <S.ContentWrapper>
            <S.TextWrapper>
              {title && <S.Title>{title}</S.Title>}
              <S.Description>
                {description && <S.Description>{description}</S.Description>}
              </S.Description>
            </S.TextWrapper>
            <div>
              <CustomButton onClick={handleOpen} className="dark-transparent">
                {button?.title}
              </CustomButton>
              <CustomDialog
                open={open}
                handleClose={handleClose}
                title="Apply for Forge SDK Beta Access."
                description="Enter your information for consideration for beta access to the Forge SDK."
              >
                <SdkBetaForm handleClose={handleClose} />
              </CustomDialog>
            </div>
          </S.ContentWrapper>
        </S.BannerWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default ReadyBanner

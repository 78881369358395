import React from "react"
import VideoPost from "../../components/video-post/video-post.component"
import * as S from "./forge-video.styles"

const ForgeVideo = ({ image, videoUrl, youtubeId }) => {
  return (
    <S.Section>
      <S.Wrapper>
        {videoUrl || youtubeId ? (
          <VideoPost
            url={videoUrl && videoUrl}
            youtubeId={youtubeId && youtubeId}
            image={image}
            overlayColor={false}
          />
        ) : (
          <S.ImagePost img={image} alt="Image Post" />
        )}
      </S.Wrapper>
    </S.Section>
  )
}

export default ForgeVideo

import React from "react"
import * as S from "./home-hero.styles"
import HeroTitle from "../HomePage/first-scene/hero-title/hero-title.component"

const HomeHero = ({ title }) => {
  return (
    <S.Wrapper>
      <HeroTitle title={title} />
      <S.HeroVideo playsInline autoPlay loop muted>
        <source src="forge-hero.mp4" type="video/mp4" />
        <source src="forge-hero.webm" type="video/webm" />
      </S.HeroVideo>
    </S.Wrapper>
  )
}

export default HomeHero

import Dialog from "@mui/material/Dialog"
import CloseIcon from "@mui/icons-material/Close"
import styled from "styled-components"

export const Wrapper = styled(Dialog)`
  .MuiPaper-root {
    ${({ theme }) => theme.breakpoints.down("xs")} {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }

  // iframe {
  //   height: 8200px;
  //    ${({ theme }) => theme.breakpoints.up("sm")}{
  //       height: 5500px;
  //    };
  // }
`

export const Close = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 3;
`

import React from "react"
import { Grid } from "@mui/material"

import * as S from "./app-marketplace.styles"

const AppMarketplace = ({
  title,
  description,
  subtitle,
  iconImage,
  iconTitle,
  iconDescription,
  link,
  image,
}) => {
  return (
    <S.Section id="Marketplace">
      <S.Wrapper>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <S.ContentContainer>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <S.Subtitle>{subtitle}</S.Subtitle>
              <S.IconDescriptionContainer>
                <S.IconImage img={iconImage} alt="icon" />
                <S.IconTitle>{iconTitle}</S.IconTitle>
              </S.IconDescriptionContainer>
              <S.Description className="icon">{iconDescription}</S.Description>
              <S.ButtonContainer>
                <S.Button href={link.url}>{link.title}</S.Button>
              </S.ButtonContainer>
            </S.ContentContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.Image img={image} alt="iconsMarketplace" />
          </Grid>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default AppMarketplace

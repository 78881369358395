import styled from "styled-components"
import Container from "@mui/material/Container"

export const Wrapper = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: white;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1em;
    padding-bottom: 1em;
  }
`
export const SectionTitle = styled.h2`
  font-size: 2.7rem;
  margin-bottom: 1.2em;
  font-family: ${({ theme }) => theme.fonts.sencondary};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

import React, { useState } from "react"
import { Grid } from "@mui/material"
import CardDemo from "../../components/card-demo/card-demo.component"

import * as S from "./forge-demo.styles"
import CustomDialog from "../../components/custom-dialog/custom-dialog.component"
import WatchDemoForm from "../../components/watch-demo-form/watch-demo-form.component"

const ForgeDemo = ({
  videoUrl,
  titleVideo,
  linkVideo,
  image,
  titleImage,
  linkImage,
}) => {
  return (
    <S.Section>
      <S.Wrapper>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <CardDemo
              haveVideo
              videoUrl={videoUrl}
              title={titleVideo}
              link={linkVideo}
              watchDemo
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardDemo
              image={image}
              title={titleImage}
              link={linkImage}
              scheduleDemo
            />
          </Grid>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default ForgeDemo

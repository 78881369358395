import React, { useState } from "react"
import * as S from "./forge-experience-section.styles"
import { Container } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomDialog from "../../components/custom-dialog/custom-dialog.component"
import ExperienceDemoForm from "../../components/experience-demo-form/experience-demo-form.component"
import { gtagConversionNewsletterClick } from "../../gtag-utils"
import { useLocation } from "@reach/router"

const ForgeExperienceSection = ({
  title,
  description,
  bottomDescription,
  button,
}) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const handleOpen = () => {
    setOpen(true)
    gtagConversionNewsletterClick(location.href)
  }
  const handleClose = () => setOpen(false)

  return (
    <S.Wrapper>
      <Container>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
        <S.IframeWrapper>
          <S.CustomIframe
            width="100%"
            src="https://www.youtube.com/embed/ti2BElmYNpw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </S.IframeWrapper>

        {bottomDescription && (
          <S.Description>{bottomDescription}</S.Description>
        )}
        <S.ButtonWrapper>
          <CustomButton onClick={handleOpen} className="blue">
            {button?.title}
          </CustomButton>
        </S.ButtonWrapper>
      </Container>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title="Schedule a Private Forge/OS Demo"
      >
        <ExperienceDemoForm handleClose={handleClose} />
      </CustomDialog>
    </S.Wrapper>
  )
}

export default ForgeExperienceSection

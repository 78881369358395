import React from "react"
import { Container, Grid, useTheme } from "@mui/material"

import * as S from "./use-description.styles"
import useMediaQuery from "@mui/material/useMediaQuery"

const UseDescription = ({
  image,
  title,
  description,
  link,
  rowReverse,
  alignLeftText,
}) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <S.Section>
      <S.GridContainer
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction={rowReverse ? "row-reverse" : "row"}
      >
        <S.GridImage item xs={12} md={6}>
          <S.Image img={image} alt="robotDescription" />
        </S.GridImage>
        <S.GridContent item xs={12} md={6}>
          <Container>
            <S.ContentContainer margin={!alignLeftText && "auto"}>
              {title && <S.Title>{title}</S.Title>}
              {description && <S.Description>{description}</S.Description>}{" "}
              {link && link?.title && (
                <S.ButtonContainer>
                  <S.Button className="black-transparent" href={link?.url}>
                    {link.title}
                  </S.Button>
                </S.ButtonContainer>
              )}
            </S.ContentContainer>
          </Container>
        </S.GridContent>
      </S.GridContainer>
    </S.Section>
  )
}

export default UseDescription

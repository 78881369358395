import styled, { css } from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import { IconButton } from "@mui/material"

const iconStyle = css`
  border: 1px solid #888888;
  color: #888888;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;

  &:hover {
    border: 1px solid #555555;
    color: #555555;
  }
`

export const Wrapper = styled(SectionWrapper)`
  .MuiContainer-root {
    position: relative;
  }
  .slick-dots {
    button {
      &::before {
        color: #d8d8d8;
        font-size: 12px;
      }
    }
    .slick-active {
      button {
        &::before {
          color: #1c72e8;
          font-size: 12px;
        }
      }
    }
  }
`

export const ArrowBackIcon = styled(IconButton)`
  ${iconStyle};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -8px;
  ${({ theme }) => theme.breakpoints.up("xl")} {
    left: -70px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: none;
  }
`
export const ArrowNextIcon = styled(IconButton)`
  ${iconStyle};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -8px;
  ${({ theme }) => theme.breakpoints.up("xl")} {
    right: -70px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: none;
  }
`
export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  column-gap: 1rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 2em;
  }
`
export const Title = styled.h2`
  max-width: 405px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    max-width: unset;
  }
`
export const Description = styled.p`
  max-width: 640px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.content};
  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: normal;
  }
`
export const ButtonContainer = styled.div`
  //margin-top: 3rem;
  display: flex;
  justify-content: center;
  margin: 3rem 1rem 0 1rem;
`
export const Button = styled(CustomButton)``

export const SliderWrapper = styled.div`
  position: relative;
  //
`
// export const ArrowContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
// `

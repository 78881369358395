import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"

export const Wrapper = styled.div`
  padding: 2em 0;
`
export const Image = styled(CustomImage)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: 50px !important;
    padding: 2em;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
`

import React, { useEffect, useRef, useState } from "react"
import * as S from "./robots-slider.styles"
import { Grid } from "@mui/material"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import CustomImage from "../../components/custom-image/custom-image.component"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import CustomButton from "../../components/custom-button/custom-button.component"

const RobotsSlider = ({ title, description, button, robots }) => {
  const slider1 = useRef()
  const slider2 = useRef()
  const [nav, setNav] = useState({
    nav1: null,
    nav2: null,
  })

  const next = () => {
    slider1.current.slickNext()
  }

  const previous = () => {
    slider1.current.slickPrev()
  }

  useEffect(() => {
    setNav({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])
  const { nav1, nav2 } = nav

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const settingsSlider = {
    arrows: false,
    dots: false,
  }

  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth="xl" style={{ height: "100%" }}>
        <S.CustomGrid
          container
          sx={{ backgroundColor: "white", borderRadius: "20px" }}
        >
          <Grid item lg={7} md={12} xs={12}>
            <S.LeftWrapper>
              <S.TabletWrapper img="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Home-V2-Toon.20.163-1-1.png" />
              <S.ImageWrapper>
                <CustomImage img={robots[0].leftImage} />
                <S.ImageSlider
                  {...settingsSlider}
                  asNavFor={nav2}
                  ref={slider => (slider1.current = slider)}
                >
                  {robots?.map(({ image }, index) => (
                    <S.LeftImage img={image} key={`image-carrousel-${index}`} />
                  ))}
                </S.ImageSlider>
              </S.ImageWrapper>
            </S.LeftWrapper>
          </Grid>
          <Grid item lg={5} md={12} xs={12} style={{ paddingRight: "2rem" }}>
            <S.ContentWrapper>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <S.SliderWrapper>
                <S.CustomSlider
                  asNavFor={nav1}
                  ref={slider => (slider2.current = slider)}
                  {...settings}
                >
                  {robots?.map(({ logo }, index) => (
                    <S.LogoWrapper key={`partners-carrousel-${index}`}>
                      <S.Logo img={logo} />
                    </S.LogoWrapper>
                  ))}
                </S.CustomSlider>
                <S.ArrowBackIcon onClick={previous}>
                  <ArrowBack />
                </S.ArrowBackIcon>
                <S.ArrowNextIcon onClick={next}>
                  <ArrowForward />
                </S.ArrowNextIcon>
              </S.SliderWrapper>
              {button?.title && button?.url && (
                <S.ButtonWrapper>
                  <CustomButton className="black-transparent" href={button.url}>
                    {button.title}
                  </CustomButton>
                </S.ButtonWrapper>
              )}
            </S.ContentWrapper>
          </Grid>
        </S.CustomGrid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default RobotsSlider

import styled from "styled-components"
import Container from "@mui/material/Container"
import CustomImage from "../../components/custom-image/custom-image.component"
import { PlayCircle } from "@mui/icons-material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import WhiteArrow from "../../images/arrow-white.svg"
export const Section = styled(SectionWrapper)`
  background-color: white;
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  button {
    max-width: unset;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiGrid-container {
    gap: 4em;
  }
  img {
    object-fit: cover !important;
  }
  .post {
    border-radius: 0;
  }
  .Mui-selected {
    ::after {
      background: url(${WhiteArrow}) no-repeat center center !important;
      transform: unset !important;
    }
  }
`
export const IconImage = styled(CustomImage)`
  width: 100px;
  height: 100px;
  padding-bottom: 2em;
`

import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Container } from "@mui/material"

export const BgImage = styled(CustomBgImage)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  //height: calc(100vh - 80px);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 568px;
  &:after {
    content: "";
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    min-height: 300px;
  }
`
export const Wrapper = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`

export const Title = styled.h1`
  //font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  //line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  //font-weight: 500;
  // font-family: ${({ theme }) => theme.fonts.secondary};
  color: white;
  max-width: 900px;
  width: 100%;
  text-align: center;
  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   font-size: 2rem;
  //   line-height: normal;
  // }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  max-width: 630px;
  text-align: center;
  padding-top: 0.5em;
  margin: 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
export const ButtonContainer = styled.div``
export const Button = styled(CustomButton)`
  margin-top: 1em;
`

import styled from "styled-components"
import Container from "@mui/material/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, MenuItem, Select } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background-color: white;
  padding: 2em 0;
`

export const TopGrid = styled(Grid)`
  padding: 0 0 4em;
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: normal;
  }
`

export const CustomMenuItem = styled(MenuItem)``

export const CustomSelect = styled(Select)``

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
`
export const BgImage = styled(CustomBgImage)``

export const Wrapper = styled(Container)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0;
    height: 750px;
  }
`
export const ContentContainer = styled.div`
  max-width: 450px;
  margin-top: 3rem;
  margin-bottom: 5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 2rem;
    margin-bottom: 0;
    margin-top: 0;
    position: relative;
    z-index: 1;
  }
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-bottom: 3rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 1rem;
  }
`
export const Description = styled.p`
  max-width: 449px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
`

export const Image = styled(CustomImage)`
  height: 550px;
  width: 800px;
`
export const ImageContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    position: relative;
    top: -100px;
  }
`

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Section = styled(SectionWrapper)``
export const Wrapper = styled(Container)``
export const ContentContainer = styled.div``
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  color: ${({ theme }) => theme.palette.text.content};
  &.icon {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
    max-width: 293px;
  }
`
export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(1)};
  font-weight: 400;
`
export const IconDescriptionContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`
export const IconImage = styled(CustomImage)`
  width: 24px;
  height: 24px;
`
export const IconTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
`
export const ButtonContainer = styled.div``
export const Button = styled(CustomButton)`
  border-radius: 20px;
  background-color: #888888;
`
export const Image = styled(CustomImage)``

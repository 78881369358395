import React from "react"

import * as S from "./banner-description.styles"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

const BannerDescription = ({ title, description, link }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const videos = {
    DESKTOP_VIDEO:
      "https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/Apps-Build-Custom-Module.mp4",
    MOBILE_VIDEO:
      "https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/950-Build-Custom-Module-1.mp4",
  }

  return (
    <S.Section>
      <S.Video
        playsInline
        autoPlay
        loop
        muted
        src={isMobile ? videos.MOBILE_VIDEO : videos.DESKTOP_VIDEO}
      />
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        {link && (
          <S.ButtonContainer>
            <S.Button href={link.url} className="white-transparent">
              {link.title}
            </S.Button>
          </S.ButtonContainer>
        )}
      </S.Wrapper>
    </S.Section>
  )
}

export default BannerDescription

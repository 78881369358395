import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(CustomBgImage)`
  height: 900px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
    text-align: center;
  }
`

export const ContentWrapper = styled.div`
  max-width: 510px;
  margin-left: auto;
`

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(50)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 72px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(60)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(68)};
  }
`

export const Subtitle = styled.h3`
  max-width: 448px;
  padding: 1em 0;
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`

export const Description = styled(Typography)`
  max-width: 448px;
  color: #566171;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1em;

  p {
    margin: unset;
  }
`

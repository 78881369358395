import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Wrapper = styled(CustomBgImage)`
  height: 700px;
`

export const ContentWrapper = styled.div`
  max-width: 450px;
  margin-left: auto;
`

export const TopWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 48px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Subtitle = styled.h3`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
color: ${({ theme }) => theme.palette.text.content};
font-size: ${({ theme }) => theme.typography.pxToRem(20)};
letter-spacing: 0;
line-height: 32px;
margin-bottom: 1em;

${({ theme }) => theme.breakpoints.down("md")} {
  font-size: 1rem;
  line-height: normal;
}
`

export const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
`

export const InfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
`

export const MobileVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border: none;
`

export const MobileWrapper = styled(SectionWrapper)`
  padding: 0;
  background-color: #d9d8da;
`

export const MobileTextWrapper = styled.div`
  padding: 4em 0;
`
export const Button = styled(CustomButton)``

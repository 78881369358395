import styled from "styled-components"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.palette.primary.main} 50%,
    ${({ theme }) => theme.palette.tertiary.main} 50%
  );
`
export const Wrapper = styled(Container)``
export const ImagePost = styled(CustomImage)`
  width: 100%;
  height: 100%;
`

import styled, { css } from "styled-components"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { FormControl, InputLabel, TextareaAutosize } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"
import CustomLink from "../custom-link/custom-link.component"

const textInputStyle = css`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
`

export const FormDiv = styled.div`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  border-radius: 20px;
  padding: 2.5rem;
  max-width: 400px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: auto;
  }
`
export const TitleForm = styled.h3`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  margin-bottom: 2rem;
`
export const CustomForm = styled.form``
export const CustomField = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    //color: ${({ theme }) => theme.palette.text.content};
    //border-bottom: 1px solid #979797;
  }
  .MuiInputLabel-root {
    ${textInputStyle}
  }
`

export const CustomInput = styled(InputLabel)`
  ${textInputStyle}
`
export const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: 1rem;
  .MuiSelect-select {
    color: black;
  }
`
export const CustomMenuItem = styled(MenuItem)`
  ${textInputStyle}
`

export const ButtonDiv = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  margin-top: 1rem;
`
export const Button = styled(CustomButton)`
  //width: 100%;
`
export const Link = styled(CustomLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`
export const TermsDiv = styled.div`
  margin: 1em 0;
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import CaseStudyCard from "../case-study-card/case-study-card.component"
import CustomPagination from "../custom-pagination/custom-pagination.component"
import * as S from "../../layouts/CaseStudiesGrid/case-studies-grid.styles"

const CaseStudiesGridPagination = ({ caseStudies }) => {
  const [articlesToShow, setArticlesToShow] = useState([])
  /*Pagination*/
  const POST_PER_PAGE = 9
  const [paginationCount, setPaginationCount] = useState(0)
  const [page, setPage] = useState(1)
  const [initialArticle, setInitialArticle] = useState(1)
  const [finalArticle, setFinalArticle] = useState(POST_PER_PAGE)

  const handlePaginationChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    setInitialArticle(1)
    setFinalArticle(
      initialArticle + POST_PER_PAGE > caseStudies?.length
        ? caseStudies?.length
        : finalArticle + POST_PER_PAGE
    )
  }, [])

  useEffect(() => {
    setArticlesToShow(
      caseStudies?.slice(
        page * POST_PER_PAGE - POST_PER_PAGE,
        page * POST_PER_PAGE
      )
    )

    setPaginationCount(Math.ceil(caseStudies?.length / POST_PER_PAGE))

    if (caseStudies?.length <= POST_PER_PAGE * (page - 1)) {
      setPage(1)
    }
  }, [page, caseStudies])

  const paginationLocal = {
    currentPage: page,
    numPages: paginationCount,
  }

  return (
    <div>
      <Grid container spacing={8}>
        {articlesToShow.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={`news-page-card-${index}`}>
            <CaseStudyCard
              excerpt={item?.excerpt}
              featuredImage={item?.featuredImage.node}
              logo={item?.caseStudyBuilder?.logo}
              url={`/resources${item?.uri}`}
            />
          </Grid>
        ))}
      </Grid>
      <S.PaginationContainer>
        {POST_PER_PAGE <= caseStudies?.length && (
          <CustomPagination
            scrollTo="case-studies"
            pageContext={paginationLocal}
            handlePagination={handlePaginationChange}
          />
        )}
      </S.PaginationContainer>
    </div>
  )
}

export default CaseStudiesGridPagination

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomLink from "../../components/custom-link/custom-link.component"
import Container from "@mui/material/Container"

export const Section = styled(SectionWrapper)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
export const BgImage = styled(CustomBgImage)``
export const ContentContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 4rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1rem;
  }
`
export const Wrapper = styled(Container)``
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  max-width: 639px;
  margin: auto;
  &.logo {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: normal;
  }
`
export const IconsContainer = styled(Grid)`
  margin: 2rem 0;
`
export const Item = styled(Grid)``
export const CardLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 2rem;
  }
`
export const Image = styled(CustomImage)``

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  margin-right: 0.3em;
`

export const LinkWrapper = styled(CustomLink)`
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  svg {
    transition: all 0.3s ease;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
  //&:hover {
  //  span {
  //    color: white;
  //  }
  //  svg {
  //    transform: translateX(25%);
  //    fill: white;
  //  }
  //}
`
export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

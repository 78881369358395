import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding: 0;
  background-color: #f5f5f5;
`

export const HeroVideo = styled.video`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: calc(100vh - 310px);
    min-height: 600px;
    max-height: 800px;
  }
`

import React from "react"
import * as S from "./home-platform-section.styles"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

const HomePlatformSection = ({ title, description, image }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <CustomImage img={image} />
          </Grid>
          <Grid item xs={12} md={4}>
            <S.ContentWrapper>
              {title && <S.Title>{title}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
            </S.ContentWrapper>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default HomePlatformSection

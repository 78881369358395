import React, { useState } from "react"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import parse from "html-react-parser"

import * as S from "./productivity-description.styles"

const ProductivityDescription = ({
  images,
  iconImage,
  title,
  description,
  link,
  productivityItems,
  backgroundColor,
  colorShadowIcon,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [indexImage, setIndexImage] = useState(0)

  const handleChange = (panel, index) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    setIndexImage(index)
  }

  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("sm"))
  const directionVariable = isMD
    ? "column-reverse"
    : backgroundColor === "black"
    ? "row-reverse"
    : "row"
  return (
    <S.Section
      color={backgroundColor === "black" ? "#000000" : "#FFFFFF"}
      id={title}
    >
      <S.Wrapper maxWidth="xl">
        <Grid
          container
          spacing={4}
          justifyContent="center"
          direction={backgroundColor === "black" ? "row" : "row-reverse"}
        >
          <S.GridContent item xs={12} md={6}>
            <S.ContentDiv>
              <S.IconImage
                img={iconImage?.sourceUrl}
                alt="logo"
                colorShadow={colorShadowIcon}
              />
              <S.Title
                color={backgroundColor === "black" ? "#FFFFFF" : "#09090B"}
              >
                {title}
              </S.Title>
              <S.Description
                color={backgroundColor === "black" ? "#A0AABA" : "#566171"}
              >
                {parse(description)}
              </S.Description>
              <S.AccordionDiv>
                {productivityItems.map(({ title, description }, index) => (
                  <S.CustomAccordion
                    key={index - title}
                    color={backgroundColor === "black" ? "#000000" : "#FFFFFF"}
                    border={
                      backgroundColor === "black"
                        ? "1px solid #4E4E4E"
                        : "1px solid #B7B7B7"
                    }
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`, index)}
                  >
                    <S.CustomAccSummary
                      expandIcon={
                        <S.Icon
                          color={
                            backgroundColor === "black" ? "#FFFFFF" : "#000000"
                          }
                        />
                      }
                      color={
                        backgroundColor === "black" ? "#000000" : "#FFFFFF"
                      }
                      textColor={
                        backgroundColor === "black" ? "#FFFFFF" : "#000000"
                      }
                    >
                      {title}
                    </S.CustomAccSummary>
                    <S.CustomAccDetails
                      color={
                        backgroundColor === "black" ? "#000000" : "#FFFFFF"
                      }
                      textColor={
                        backgroundColor === "black" ? "#A0AABA" : "#566171"
                      }
                    >
                      {description}
                    </S.CustomAccDetails>
                  </S.CustomAccordion>
                ))}
              </S.AccordionDiv>
              {/*<S.Button className="light-transparent" url={link.url}>*/}
              {/*  {link.title}*/}
              {/*</S.Button>*/}
            </S.ContentDiv>
          </S.GridContent>
          <Grid item xs={12} md={6}>
            <S.Image img={images[indexImage]?.image} alt="devImage" />
            {/*<S.Gradient*/}
            {/*  color={*/}
            {/*    backgroundColor === "black"*/}
            {/*      ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)"*/}
            {/*      : "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)"*/}
            {/*  }*/}
            {/*/>*/}
            {/*</S.Image>*/}
          </Grid>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default ProductivityDescription

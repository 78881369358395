import React from "react"
import "react-modal-video/css/modal-video.min.css"
import * as S from "./device-control-section.styles.jsx"
import VerticalTabs from "../../components/vertical-tabs/vertical-tabs.component"

const DeviceControlSection = ({ title, description, tabs, iconImage }) => {
  return (
    <S.Section contained>
      {iconImage && <S.IconImage img={iconImage?.sourceUrl} alt="icon" />}
      <VerticalTabs
        title={title}
        description={description}
        tabs={tabs}
        className="device-control"
      />
    </S.Section>
  )
}
export default DeviceControlSection

import React, { useState } from "react"

import * as S from "./card-demo.styles"
import CustomDialog from "../custom-dialog/custom-dialog.component"
import WatchDemoForm from "../watch-demo-form/watch-demo-form.component"
import ScheduleDemoForm from "../schedule-demo-form/schedule-demo-form.component"

const CardDemo = ({
  title,
  description,
  link,
  haveVideo,
  videoUrl,
  image,
  watchDemo,
  scheduleDemo,
}) => {
  const [openWatchDemo, setOpenWatchDemo] = useState(false)
  const handleOpenWatchDemo = () => setOpenWatchDemo(true)
  const handleCloseWatchDemo = () => setOpenWatchDemo(false)

  const [openScheduleDemo, setOpenScheduleDemo] = useState(false)
  const handleOpenScheduleDemo = () => setOpenScheduleDemo(true)
  const handleCloseScheduleDemo = () => setOpenScheduleDemo(false)

  return (
    <S.Wrapper>
      <S.MediaContainer>
        {!haveVideo ? (
          <S.RightImage img={image} />
        ) : (
          <S.Video playsInline autoPlay loop muted src={videoUrl} />
        )}
      </S.MediaContainer>
      <S.ContentContainer>
        <S.TextWrapper>
          {title && <S.Title>{title}</S.Title>}
          <S.Button
            className="blue"
            onClick={watchDemo ? handleOpenWatchDemo : handleOpenScheduleDemo}
          >
            {link.title}
          </S.Button>
        </S.TextWrapper>
      </S.ContentContainer>

      <CustomDialog
        open={openWatchDemo}
        handleClose={handleCloseWatchDemo}
        title="Watch Demo"
      >
        <WatchDemoForm
          buttonText="Watch the Forge/OS Demo"
          close={handleCloseWatchDemo}
        />
      </CustomDialog>
      <CustomDialog
        open={openScheduleDemo}
        handleClose={handleCloseScheduleDemo}
        title="Schedule Private Demo"
      >
        <ScheduleDemoForm
          buttonText="Schedule Private Demo"
          close={handleCloseScheduleDemo}
        />
      </CustomDialog>
    </S.Wrapper>
  )
}

export default CardDemo

import React, { useRef } from "react"
import SalesCarrouselItem from "../../components/sales-carrousel-item/sales-carrousel-item.component"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as S from "./sales-carrousel.styles"
import parse from "html-react-parser"

const SalesCarrousel = ({
  sectionTitle,
  sectionDescription,
  carrouselItems,
}) => {
  const slider = useRef()

  const next = () => {
    slider.current.slickNext()
  }

  const previous = () => {
    slider.current.slickPrev()
  }

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  }

  return (
    <S.Section>
      <S.Wrapper maxWidth="md" id="facilities">
        {sectionTitle && sectionDescription && (
          <S.ContentContainer>
            <S.SectionTitle>{sectionTitle}</S.SectionTitle>
            <S.SectionDescription>
              {parse(sectionDescription)}
            </S.SectionDescription>
          </S.ContentContainer>
        )}
        <S.CustomSlider ref={slider} {...settings}>
          {carrouselItems?.map(
            (
              { bgImage, title, description, salesLink, stateImgMap },
              index
            ) => (
              <SalesCarrouselItem
                key={`sales-carrousel-${index}`}
                bgImage={bgImage}
                title={title}
                description={description}
                salesLink={salesLink}
                stateImgMap={stateImgMap}
              />
            )
          )}
        </S.CustomSlider>
      </S.Wrapper>
      <S.CarrouselNav>
        <S.ArrowBackIcon onClick={previous} />
        <S.ArrowNextIcon onClick={next} />
      </S.CarrouselNav>
    </S.Section>
  )
}

export default SalesCarrousel

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #eef7ff;
`

export const InfoLink = styled.a`
  border-radius: 20px;
  text-decoration: none;
  height: 100%;
  width: 100%;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
`

export const InfoWrapper = styled.div`
  color: #ffffff;
  padding: 4em;
  background: linear-gradient(154.46deg, #15151a 0%, #09090b 100%);
  border-radius: 20px;
`

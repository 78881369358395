import React from "react"
import * as S from "./centered-misc-content.styles.jsx"
import parse from "html-react-parser"

const CenteredMiscContent = ({ containerMaxWidth, content }) => {
  if (!content) return null
  return (
    <S.Section>
      <S.Wrapper maxWidth={containerMaxWidth}>
        <S.ContentContainer>{parse(content)}</S.ContentContainer>
      </S.Wrapper>
    </S.Section>
  )
}
export default CenteredMiscContent

import styled from "styled-components"
import { Grid, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomLink from "../../components/custom-link/custom-link.component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

export const Wrapper = styled(SectionWrapper)`
  background-color: #09090b;
  color: white;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 0;
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: #a0aaba;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const FeatureTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1em 0 0.5em;
`

export const FeatureDescription = styled(Typography)`
  color: #a0aaba;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
`

export const FeatureWrapper = styled.div`
  max-width: 293px;
`

export const FeatureImage = styled(CustomImage)`
  width: 80px;
  height: 80px;
  img {
    ${({ theme }) => theme.breakpoints.up("md")} {
      filter: drop-shadow(0px 0px 16px ${props => props.shadowColor});
    }
    width: 100%;
    height: 100%;
  }
`

export const LeftImage = styled(CustomImage)`
  width: 100%;
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    img {
      transform: scale(1.1) !important;
      margin-top: 1.5em !important;
    }
  }
`

export const RightWrapper = styled.div`
  margin-top: 250px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 0;
    position: relative;
    top: -50px;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Link = styled(CustomLink)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`

// export const LinkWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   transition: 0.2s ease-in;
//   &:hover {
//     svg {
//       transform: translateX(5px);
//     }
//   }
// `

export const BottomWrapper = styled.div`
  margin-top: 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    justify-content: center;
  }
`
export const Arrow = styled(ArrowForwardIcon)`
  transition: 0.2s ease-in;
  fill: ${({ theme }) => theme.palette.secondary.main};
  margin-left: 5px;
`
export const GridLogos = styled(Grid)`
  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   height: 700px;
  // }
`

export const LinkText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  margin-right: 0.3em;
`

export const LinkWrapper = styled(CustomLink)`
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  svg {
    transition: all 0.3s ease;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
  //&:hover {
  //  span {
  //    color: white;
  //  }
  //  svg {
  //    transform: translateX(25%);
  //    fill: white;
  //  }
  //}
`
export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`
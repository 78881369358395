import React, { useRef, useState } from "react"
import * as S from "./schedule-demo-form.styles"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import Typography from "@mui/material/Typography"
import CustomButton from "../custom-button/custom-button.component"
import { submitGravityForm } from "../../utils"
import { useLocation } from "@reach/router"
import Spinner from "../spinner/spinner.component"
import { Grid } from "@mui/material"
import ThankYouMessage from "../thank-you-message/thank-you-message.component"

const ScheduleDemoForm = ({ buttonText }) => {
  const recaptchaRef = useRef()
  const location = useLocation()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const handleCaptchaChange = () => {
    setError(false)
  }
  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    setIsLoading(true)
    const captchaVerification = recaptchaRef.current.getValue()
    if (captchaVerification === "") {
      setError(true)
      setIsLoading(false)
      return
    }
    submitGravityForm(
      {
        input_1: data.first_name,
        input_2: data.last_name,
        input_4: data.email,
        input_5: data.company,
        input_6: location.href,
      },
      7
    ).then(response => {
      setIsLoading(false)
      setFormSubmitted(true)
    })
  }

  return (
    <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Spinner />}
      {!formSubmitted ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <S.CustomField
                required
                id="firstname"
                label="First Name"
                variant="standard"
                {...register("first_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <S.CustomField
                required
                id="lastname"
                label="Last Name"
                variant="standard"
                {...register("last_name")}
              />
            </Grid>
            <Grid item xs={12}>
              <S.CustomField
                required
                id="email"
                label="Email Address"
                variant="standard"
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <S.CustomField
                required
                id="company"
                label="Company"
                variant="standard"
                {...register("company")}
              />
            </Grid>
          </Grid>
          <S.TermsDiv>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_CAPTCHA_CLIENT_KEY}
              size="normal"
              render="explicit"
              onChange={handleCaptchaChange}
              className="g-recaptcha"
              ref={recaptchaRef}
            />
            {error && (
              <Typography style={{ color: "#F54343" }}>
                Please enter captcha
              </Typography>
            )}
          </S.TermsDiv>
          <S.Link url="/privacy-policy/">Terms and Privacy Policy.</S.Link>
          <S.ButtonDiv>
            <CustomButton
              className="blue"
              children={buttonText}
              type="submit"
            />
          </S.ButtonDiv>
        </>
      ) : (
        <ThankYouMessage
          message={`<h3>Thank you!</h3><p>A member of our team will be in touch soon.</p>`}
        />
      )}
    </S.CustomForm>
  )
}

export default ScheduleDemoForm

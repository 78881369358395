import React from "react"
import { Grid } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"

import * as S from "./sales-carrousel-item.syles"

const SalesCarrouselItem = ({
  bgImage,
  title,
  description,
  salesLink,
  stateImgMap,
}) => {
  return (
    <S.CarrouselItem>
      <S.CarrouselImage img={bgImage} />
      <S.CarrouselOverlay>
        <S.GridContainer container spacing={4}>
          <Grid item md={6}>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
            <CustomButton href={salesLink} className="blue">
              Contact Sales
            </CustomButton>
          </Grid>
          <Grid item md={6}>
            <S.MapImg img={stateImgMap} />
          </Grid>
        </S.GridContainer>
      </S.CarrouselOverlay>
    </S.CarrouselItem>
  )
}

export default SalesCarrouselItem

import React from "react"
import * as S from "./image-info-row.styles"
import { Container } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import parse from "html-react-parser"

const ImageInfoRow = ({
  backgroundImage,
  title,
  subtitle,
  description,
  button,
}) => {
  return (
    <S.Wrapper img={backgroundImage}>
      <Container maxWidth="xl">
        <S.ContentWrapper>
          {title && <S.Title>{title}</S.Title>}
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          {description && <S.Description>{parse(description)}</S.Description>}
          <CustomButton href={button?.url} className="light-transparent">
            {button?.title}
          </CustomButton>
        </S.ContentWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default ImageInfoRow

import styled from "styled-components"
import Container from "@mui/material/Container"
import CustomImage from "../../components/custom-image/custom-image.component"
import { PlayCircle } from "@mui/icons-material"

export const Section = styled.section`
  background-color: white;
`

export const Wrapper = styled(Container)`
  padding: 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1rem;
  }
`

export const ContentContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 55%;
  }
`

export const SectionTitle = styled.h1`
  text-align: center;
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 44px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const SectionContent = styled.p`
  color: #566171;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 24px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 25px;
`

export const VideoContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
`

export const AboutImage = styled(CustomImage)`
  width: 100%;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  display: block;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const PlayCircleIcon = styled(PlayCircle)`
  color: white;
  width: 3em;
  height: 3em;
`

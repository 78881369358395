import React, { useState } from "react"

import * as S from "./description-accordion.styles"

const DescriptionAccordion = ({ items }) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <S.Section>
      {items.map(
        (
          {
            title,
            descriptionTitle,
            titleDetails,
            descriptionDetails,
            bgImage,
          },
          index
        ) => (
          <S.CustomAccordion
            key={index - title}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <S.CustomAccSummary>
              <S.Wrapper maxWidth="xl" className="summaryAccordion">
                <S.Title className="titleSummary">{title}</S.Title>
                <S.Description className="descriptionSummary">
                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                </S.Description>
              </S.Wrapper>
            </S.CustomAccSummary>
            <S.CustomAccDetails>
              <S.Wrapper maxWidth="xl" className="summary">
                <S.Title>{title}</S.Title>
                <S.Description>{descriptionTitle}</S.Description>
              </S.Wrapper>
              <S.BgImage img={bgImage} alt={index - `bgImage`} tag="div">
                <S.Wrapper maxWidth="xl">
                  <S.NumberDiv>
                    <S.Description>
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </S.Description>
                    <S.Icon onClick={handleChange(`panel${index}`)} />
                  </S.NumberDiv>
                  <S.ContentDiv>
                    <S.Title className="titleDetails">{titleDetails}</S.Title>
                    <S.Description className="titleDetails">
                      {descriptionDetails}
                    </S.Description>
                  </S.ContentDiv>
                </S.Wrapper>
              </S.BgImage>
            </S.CustomAccDetails>
          </S.CustomAccordion>
        )
      )}
    </S.Section>
  )
}

export default DescriptionAccordion

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import {Typography} from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  min-height: 800px;

  ${({theme}) => theme.breakpoints.down("md")} {
    min-height: 700px;
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 550px;
  margin: auto;

  ${({theme}) => theme.breakpoints.down("md")} {
    button,
    a {
      margin: auto;
    }
  }
`

export const Title = styled.h2`
  color: ${({theme}) => theme.palette.primary.main};
  font-family: ${({theme}) => theme.fonts.secondary};
  font-size: ${({theme}) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;

  ${({theme}) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    width: 100%;
    text-align: center;
  }
`

export const Description = styled(Typography)`
  color: ${({theme}) => theme.palette.text.content};
  font-family: ${({theme}) => theme.fonts.primary};
  font-size: ${({theme}) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0;

  ${({theme}) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
  }
`

export const Image = styled(CustomImage)`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

    // ${({theme}) => theme.breakpoints.down("md")} {
  //   display: none;
  // }
`

export const ImageWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  position: relative;
`

export const Video = styled.video`
  object-fit: cover;
  position: absolute;
  

  ${({theme}) => theme.breakpoints.up("md")} {
    top: 80px;
    left: 71px;
    width: 308px;
    height: 490px;
  }

  ${({theme}) => theme.breakpoints.down("md")} {
    top: 55px;
    left: 48px;
    width: 218px;
    height: 346px;
  }

  @media only screen and (max-width: 375px) {
    top: 49px;
    left: 43px;
    width: 186px;
    height: 298px;
  }
`

export const RightWrapper = styled.div`
  height: 100%;

  ${({theme}) => theme.breakpoints.down("md")} {
    padding-top: 2em;
    max-width: 350px;
    margin: auto;
  }
`

export const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
`

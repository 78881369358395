import React from "react"
import { Grid } from "@mui/material"

import * as S from "./forge-tabs.styles"

const ForgeTabs = ({ title, tabs }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <S.Section>
      <S.Title>{title}</S.Title>
      <S.CustomTabContext value={value}>
        <S.Wrapper>
          <S.CustomTabs
            value={value}
            onChange={handleChange}
            centered
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs?.map(({ name }, index) => (
              <S.CustomTab label={name} value={index} key={index - name} />
            ))}
          </S.CustomTabs>
        </S.Wrapper>
        {tabs?.map(({ title, callout1, callout2, image, name }, index) => (
          <S.CustomTabPanel value={index} key={index - name}>
            {/*<S.Wrapper>*/}
            <S.GridContainer
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <S.ContentContainer>
                  <S.Title className="tab">{title}</S.Title>
                  <S.Description>{callout1}</S.Description>
                  <S.Description>{callout2}</S.Description>
                </S.ContentContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <S.Image img={image} alt="tabImage" />
              </Grid>
            </S.GridContainer>
            {/*</S.Wrapper>*/}
          </S.CustomTabPanel>
        ))}
      </S.CustomTabContext>
    </S.Section>
  )
}

export default ForgeTabs

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)``

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: #09090b;
  font-size: ${({ theme }) => theme.typography.pxToRem(68)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 72px;
  text-align: center;
  max-width: 852px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
  text-align: center;
`

export const IframeWrapper = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 1em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const CustomIframe = styled.iframe`
  height: 500px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 300px;
  }
`

import React from "react"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material"

import * as S from "./robots-usability.styles"

const RobotsUsability = ({ image, title, description }) => {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <S.Section>
      {isMD ? (
        <S.Wrapper maxWidth="xl">
          <S.ContentContainer>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.ContentContainer>
          <S.ImageContainer>
            <S.Image img={image} alt="robot" />
          </S.ImageContainer>
        </S.Wrapper>
      ) : (
        <S.BgImage img={image} alt="robot">
          <S.Wrapper maxWidth="xl">
            <S.ContentContainer>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
            </S.ContentContainer>
          </S.Wrapper>
        </S.BgImage>
      )}
    </S.Section>
  )
}

export default RobotsUsability

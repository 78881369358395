import React, { useRef } from "react"
import CardLocation from "../../components/card-location/card-location.component"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import * as S from "./location-slider.styles"

const LocationSlider = ({ sliders }) => {
  // const slider = useRef()
  // const next = slider => {
  //   slider.current.slickNext()
  // }
  //
  // const previous = slider => {
  //   slider.current.slickPrev()
  // }
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    adaptiveHeight: true,
    fade: true,
  }

  return (
    <S.Section>
      {sliders.map(({ slider }, index) => (
        <S.CustomSlider ref={slider} {...settings} key={index}>
          {slider.map(
            (
              { image, mapImage, title, description, subtitle },
              indexSlider
            ) => (
              <CardLocation
                image={image}
                mapImage={mapImage}
                title={title}
                description={description}
                subtitle={subtitle}
                slider={slider}
                key={indexSlider - title}
              />

              // <S.CardContainer key={indexSlider - title}>
              //   <S.BgImage img={image} alt="location" tag="div">
              //     {/*<Grid container justifyContent="center" alignItems="center" spacing={3}>*/}
              //     {/*  <Grid item xs={12} md={6}>*/}
              //     <S.ContentContainer>
              //       {/*<S.CustomSlider {...settings}>*/}
              //       <S.ImageContainer>
              //         <S.MapImage img={mapImage} alt="map" />
              //       </S.ImageContainer>
              //       <S.Title>{title}</S.Title>
              //       <S.Subtitle>{subtitle}</S.Subtitle>
              //       <S.Description>{description}</S.Description>
              //       <S.CarrouselNav>
              //         <S.ArrowBackIcon onClick={() => previous(slider)} />
              //         <S.ArrowNextIcon onClick={() => next(slider)} />
              //       </S.CarrouselNav>
              //       {/*</S.CustomSlider>*/}
              //     </S.ContentContainer>
              //     {/*</Grid>*/}
              //     {/*<Grid item xs={12} md={6}>*/}
              //
              //     {/*</Grid>*/}
              //     {/*</Grid>*/}
              //   </S.BgImage>
              // </S.CardContainer>
            )
          )}
        </S.CustomSlider>
      ))}
    </S.Section>
  )
}

export default LocationSlider

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 3rem 1rem;
  }
`
export const Wrapper = styled(Container)``
export const GridText = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    justify-content: center;
    padding-right: 1.25rem;
  }
`
export const ContentContainer = styled.div`
  max-width: 450px;
`
export const ImageContainer = styled.div`
  //.gatsby-image-wrapper {
  //  max-height: 50px;
  //  min-height: 50px;
  //  img {
  //    object-fit: contain !important;
  //  }
  //}
  margin-bottom: 2.5rem;
`
export const Image = styled(CustomImage)``
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  margin-bottom: 2.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`
export const GridImage = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-right: 1.25rem;
  }
`

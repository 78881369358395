import React from "react"
import * as S from "./results-row.styles"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import parse from "html-react-parser"

const ResultsRow = ({
  topTitle,
  middleTitle,
  leftTitle,
  description,
  image,
}) => {
  return (
    <S.Wrapper noPaddingTop>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item lg={4} md={12} xs={12}>
            <S.TopWrapper>
              <S.TextWrapper>
                {topTitle && <S.TopTitle>{topTitle}</S.TopTitle>}
                {middleTitle && <S.MiddleTitle>{middleTitle}</S.MiddleTitle>}
                {leftTitle && <S.BottomTitle>{parse(leftTitle)}</S.BottomTitle>}
                {description && <S.Description>{description}</S.Description>}
              </S.TextWrapper>                
                <S.ButtonWrapper>
                  <CustomButton
                    className="black-transparent"
                    href="/resources/case-studies/"
                  >
                    View Case Studies
                  </CustomButton>
                </S.ButtonWrapper>
            </S.TopWrapper>
          </Grid>
          <Grid item lg={8} md={12} xs={12}>
            <S.Image img={image} />
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default ResultsRow

import Slider from "react-slick"
import styled, { css } from "styled-components"
import Container from "@mui/material/Container"
import { ArrowBack, ArrowForward } from "@mui/icons-material"

const iconStyle = css`
  border: 1px solid #888888;
  color: #888888;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;

  &:hover {
    border: 1px solid #555555;
    color: #555555;
  }
`

export const Section = styled.section`
  background-color: white;
  padding: 1em 0;
`

export const Wrapper = styled(Container)`
  padding-top: 2em;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentContainer = styled.div`
  text-align: center;
  padding-bottom: 4em;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 75%;
  }
`

export const SectionTitle = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 36px;
`

export const SectionDescription = styled.p`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.primary};
`

export const CustomSlider = styled(Slider)`
  width: 100%;

  .slick-list {
    overflow: unset;
  }
  .slick-slide {
    opacity: 0.3;
    transition: 0.5s ease all;

    &.slick-active {
      display: block;
      transform: scale(1.2);
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }

  .slick-arrow {
    display: none !important;
  }
`

export const CarrouselNav = styled.div`
  display: flex;
  justify-content: center;
`

export const ArrowBackIcon = styled(ArrowBack)`
  ${iconStyle};
  margin-right: 1em;
`
export const ArrowNextIcon = styled(ArrowForward)`
  ${iconStyle};
  margin-left: 1em;
`

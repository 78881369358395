import React from "react"
import * as S from "./hero-title.styles"
import { Fade } from "react-awesome-reveal"

const HeroTitle = ({ title }) => {
  return (
    <S.TitleSection>
      <Fade direction="down">{title && <S.Title>{title}</S.Title>}</Fade>
    </S.TitleSection>
  )
}

export default HeroTitle

import React, { useState } from "react"
import LoadableModalVideo from "../../components/loadable-modal-video/loadable-modal-video.component"
import "react-modal-video/css/modal-video.min.css"

import * as S from "./about-section.styles.jsx"

const AboutSection = ({
  containerMaxWidth,
  title,
  description,
  videoImage,
  youtubeId,
}) => {
  const [isVideoOpen, setVideoOpen] = useState(false)

  return (
    <S.Section id="our-roots">
      <S.Wrapper maxWidth={containerMaxWidth}>
        <S.ContentContainer>
          <S.SectionTitle>{title}</S.SectionTitle>
          <S.SectionContent>{description}</S.SectionContent>
        </S.ContentContainer>
        <S.VideoContainer onClick={() => (youtubeId ? setVideoOpen(true) : "")}>
          <S.AboutImage img={videoImage} alt="Robot oem module" />
          <S.Overlay>
            <S.PlayCircleIcon />
          </S.Overlay>
        </S.VideoContainer>
        <LoadableModalVideo
          channel={"youtube"}
          isOpen={isVideoOpen}
          videoId={youtubeId}
          autoplay={1}
          onClose={() => setVideoOpen(false)}
        />
      </S.Wrapper>
    </S.Section>
  )
}
export default AboutSection

import React from "react"
import * as S from "./empower-video-section.styles"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"

const EmpowerVideoSection = ({ title, description, button, image }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item sm={12} md={5} lg={6}>
            <S.TextWrapper>
              {title && <S.Title>{title}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
              {button?.title && (
                <CustomButton
                  className="black-transparent"
                  href={button?.url}
                  target={button?.target}
                >
                  {button?.title}
                </CustomButton>
              )}
            </S.TextWrapper>
          </Grid>
          <Grid item sm={12} md={7} lg={6} style={{ width: "100%" }}>
            <S.RightWrapper>
              <S.ImageWrapper>
                <S.Image img="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Home-V2-Toon.20.163-1-1.png" />
                <S.VideoWrapper>
                  <S.Video
                    playsInline
                    autoPlay
                    loop
                    muted
                    src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Task-Canvas-Loop.mp4"
                  />
                </S.VideoWrapper>
              </S.ImageWrapper>
            </S.RightWrapper>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default EmpowerVideoSection

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Section = styled(SectionWrapper)``
export const Wrapper = styled(Container)``
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const IconImage = styled(CustomImage)`
  width: 100px;
  height: 100px;
`
export const Title = styled.h2`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  ${({ smallText }) =>
    smallText &&
    `max-width:500px;
    margin-bottom: 1em;
    margin: auto;
    `}

  &.gridSection {
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: ${({ theme }) => theme.typography.pxToRem(32)};
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
      line-height: normal;
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  color: ${({ theme }) => theme.palette.text.content};
  &.deviceItems {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: normal;
  }
`
export const ImageContainer = styled.div``
export const Image = styled(CustomImage)``

export const TopTextWrapper = styled.div`
  ${({ smallText }) =>
    smallText &&
    `max-width:630px;
    margin-bottom: 2em;
    `}
`

import React from "react"
import { Grid } from "@mui/material"
import ContactForm from "../../components/contact-form/contact-form.component"

import * as S from "./hero-form.styles"

const HeroForm = ({
  backgroundImage,
  buttonName,
  titleForm,
  linkPrivacyPolicy,
}) => {
  return (
    <S.Section>
      <S.BgImage img={backgroundImage} alt="bgImage">
        <S.Wrapper>
          {/*<Grid*/}
          {/*  container*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems="center"*/}
          {/*  spacing={5}*/}
          {/*>*/}
          {/*  <Grid item xs={12} md={6}>*/}
          {/*    <S.ContentContainer>*/}
          {/*      <S.Title>{title}</S.Title>*/}
          {/*      <S.Description>{description}</S.Description>*/}
          {/*    </S.ContentContainer>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} md={6}>*/}
          <ContactForm
            title={titleForm}
            link={linkPrivacyPolicy}
            textButton={buttonName}
          />
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </S.Wrapper>
      </S.BgImage>
    </S.Section>
  )
}

export default HeroForm

import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"

export const CardDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ImageDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(19.7)};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    max-height: 150px;
    min-height: 150px;
    img {
      object-fit: contain !important;
    }
  }
`
export const MapImage = styled(CustomImage)`
  width: 100%;
  height: 100%;
`
export const ContentDiv = styled.div``
export const PlaceName = styled.h3`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Link = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-family: ${({ theme }) => theme.fonts.primary};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(1)};
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(12)};
`
export const Address = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
  padding: 0;
  p {
    margin: 0;
  }
`

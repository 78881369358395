import React from "react"
import * as S from "./cards-grid.styles"
import { Container, Grid, MenuItem } from "@mui/material"

const CardsGrid = ({ cards }) => {
  return (
    <S.Wrapper>
      <S.CardContainer>
        <S.GridContainer container justifyContent="center" spacing={5}>
          {cards.map(({ title, description, image }, index) => (
            <Grid item xs={12} md={4}>
              <S.Card>
                <S.Image img={image} />
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
              </S.Card>
            </Grid>
          ))}
        </S.GridContainer>
      </S.CardContainer>
    </S.Wrapper>
  )
}

export default CardsGrid

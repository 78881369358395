import React from "react"
import parse from "html-react-parser"

import * as S from "./card-place-map.styles"

const CardPlaceMap = ({ mapImage, placeName, address, link }) => {
  return (
    <S.CardDiv>
      <S.ImageDiv>
        <S.MapImage img={mapImage} alt={"mapPlace"} />
      </S.ImageDiv>
      <S.ContentDiv>
        <S.PlaceName>{placeName}</S.PlaceName>
        <S.Link>{link.title}</S.Link>
        <S.Address>{parse(address)}</S.Address>
      </S.ContentDiv>
    </S.CardDiv>
  )
}

export default CardPlaceMap

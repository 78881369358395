import React from "react"
import * as S from "./content-card.styles"

const ContentCard = ({ title, image, link, isFile, file, category }) => {
  return (
    <S.CardContainer
      href={isFile ? file?.localFile?.url : link}
      target="_blank"
    >
      <S.Card>
        <S.ImageContainer>
          <S.Image img={image} alt={title} />
          <S.ImageOverlay className="overlay">
            <S.LinkedInIcon />
          </S.ImageOverlay>
        </S.ImageContainer>
        <S.BlueTitle>{category}</S.BlueTitle>
        <S.Title>{title}</S.Title>
      </S.Card>
    </S.CardContainer>
  )
}

export default ContentCard

import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { Container, Grid, Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 2rem;
  }
`

export const Image = styled(CustomImage)``

export const Title = styled.h3`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1.5em 0;
`

export const Description = styled(Typography)`
  color: #8590a1;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
`

export const GridContainer = styled(Grid)``
export const CardContainer = styled(Container)``
export const Card = styled.div``

import React from "react"
import * as S from "./forge-video-hero.styles"
import { Container, Grid } from "@mui/material"
import ForgeCard from "../../components/forge-card/forge-card.component"
import { graphql, useStaticQuery } from "gatsby"

const ForgeVideoHero = ({
  title,
  leftTitle,
  leftDescription,
  leftLink,
  rightTitle,
  rightDescription,
  rightLink,
}) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      rightImage: file(relativePath: { eq: "module-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Wrapper>
      <S.TopWrapper>
        <S.BlackDiv>{title && <S.Title>{title}</S.Title>}</S.BlackDiv>
        {/*<S.GrayDiv />*/}
        <S.CardWrapper>
          <Container maxWidth="xl">
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <ForgeCard
                  title={leftTitle}
                  description={leftDescription}
                  link={leftLink}
                  haveVideo
                  watchDemo
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ForgeCard
                  title={rightTitle}
                  description={rightDescription}
                  link={rightLink}
                  image={staticQuery.rightImage}
                />
              </Grid>
            </Grid>
          </Container>
        </S.CardWrapper>
      </S.TopWrapper>
    </S.Wrapper>
  )
}

export default ForgeVideoHero

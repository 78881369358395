import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 0;
`
export const Wrapper = styled(Container)``
export const Image = styled(CustomBgImage)`
  width: 100%;
  height: 100%;
  min-height: 500px;
`
export const GridContent = styled(Grid)`
  display: flex;
  align-items: center;
`
export const ContentDiv = styled.div`
  max-width: 406px;
  margin: auto;
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(75)};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`
export const Button = styled(CustomButton)`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(32)}; ;
`
export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`

import React from "react"
import { Grid } from "@mui/material"

import * as S from "./robot-image-description.styles"

const RobotImageDescription = ({ items }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <S.GridSection container justifyContent="center">
          {items.map(({ image, title, description }, index) => (
            <S.GridContainer
              item
              container
              direction={index % 2 === 0 ? "row-reverse" : "row"}
              justifyContent="center"
              key={title - index}
            >
              <S.LeftGrid item xs={12} md={6}>
                <S.Image
                  img={image}
                  alt="robot"
                  marginLeft={index % 2 !== 0 ? "0" : "auto"}
                />
              </S.LeftGrid>
              <S.GridDescription item xs={12} md={6}>
                <S.TextContainer marginLeft={index % 2 !== 0 ? "auto" : "0"}>
                  <S.Title>{title}</S.Title>
                  <S.Description>{description}</S.Description>
                </S.TextContainer>
              </S.GridDescription>
            </S.GridContainer>
          ))}
        </S.GridSection>
      </S.Wrapper>
    </S.Section>
  )
}

export default RobotImageDescription

import styled from "styled-components"
import Container from "@mui/material/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { Grid } from "@mui/material"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"

export const Section = styled(SectionWrapper)`
  background-color: white;
`

export const Wrapper = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;
`

export const FeaturedImage = styled(CustomImage)`
  width: 100%;
  height: 508px;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 310px;
  }
`

export const TagName = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.palette.text.primary};
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  padding: 0.4em 1em;
  text-transform: uppercase;
  display: inline;
`

export const Category = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(17)};
  color: ${({ theme }) => theme.palette.text.content};
`

export const ContentWrapper = styled.div``

export const ContentGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ImageContainer = styled.div`
  display: block;
  position: relative;
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`
export const ImageOverlay = styled.div`
  background-color: rgba(0, 119, 179, 0.7);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease all;
`

export const LinkedInIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #ffffff;
  opacity: 1 !important;
  width: 64px;
  height: 64px;
`

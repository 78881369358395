import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import Slider from "react-slick"

export const Section = styled(SectionWrapper)`
  //background-color: ${({ theme }) => theme.palette.secondary.light};
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding: 0;
`
export const Wrapper = styled(Container)``
export const CustomSlider = styled(Slider)``
